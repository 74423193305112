import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  FieldArrayMethodProps,
  FieldValues,
  UseFormSetValue
} from "react-hook-form";
import { toast } from 'react-toastify';

import { getPlant } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { GetPlantQuery } from "../../../types/api";

interface getPlantDetailProp {
  dispatch: AppDispatch;
  id: string | undefined;
  setValue: UseFormSetValue<FieldValues>;
  append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
}
export const getPlantDetail: ({
  dispatch,
  id,
  setValue,
  append,
}: getPlantDetailProp) => Promise<void> = async ({
  dispatch,
  id,
  setValue,
  append,
}: getPlantDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const data: GraphQLResult<GetPlantQuery> = await API.graphql({
      query: getPlant,
      variables: { id }
    }) as GraphQLResult<GetPlantQuery>;
    if (data.data?.getPlant) {
      const plantData = data.data?.getPlant;
      setValue('id', plantData.id)
      setValue('name', plantData.name)
      setValue('type', plantData.type)
      setValue('scientific_name', plantData.scientific_name)
      setValue('name_bm', plantData.name_bm)
      setValue('name_cn', plantData.name_cn)
      setValue('temperature_min', plantData.temperature?.min)
      setValue('temperature_max', plantData.temperature?.max)
      setValue('temperature_scale_unit', plantData.temperature?.scale_unit)
      setValue('height_min', plantData.height?.min)
      setValue('height_max', plantData.height?.max)
      setValue('height_scale_unit', plantData.height?.scale_unit)
      setValue('seed_planting_depth_min', plantData.seed_planting_depth?.min)
      setValue('seed_planting_depth_max', plantData.seed_planting_depth?.max)
      setValue('seed_planting_depth_scale_unit', plantData.seed_planting_depth?.scale_unit)
      setValue('planting_distance_between_plants_min', plantData.planting_distance_between_plants?.min)
      setValue('planting_distance_between_plants_max', plantData.planting_distance_between_plants?.max)
      setValue('planting_distance_between_plants_scale_unit', plantData.planting_distance_between_plants?.scale_unit)
      setValue('pruning_requirement', plantData.pruning_requirement)
      setValue('soil_moisture', plantData.soil_moisture)
      setValue('sunlight_min', plantData.sunlight?.min)
      setValue('sunlight_max', plantData.sunlight?.max)
      setValue('sunlight_scale_unit', plantData.sunlight?.scale_unit)
      setValue('germination_min', plantData.germination?.min)
      setValue('germination_max', plantData.germination?.max)
      setValue('germination_scale_unit', plantData.germination?.scale_unit)
      setValue('harvest_day_min', plantData.harvest_day?.min)
      setValue('harvest_day_max', plantData.harvest_day?.max)
      setValue('harvest_day_scale_unit', plantData.harvest_day?.scale_unit)
      setValue('harvest_month_min', plantData.harvest_month?.min)
      setValue('harvest_month_max', plantData.harvest_month?.max)
      setValue('harvest_month_scale_unit', plantData.harvest_month?.scale_unit)
      setValue('water_amount', plantData.water_amount)
      setValue('watering_frequency', plantData.watering_frequency)
      setValue('watering_duration', plantData.watering_duration)
      setValue('plant_nature', plantData.plant_nature)
      setValue('pest_info', plantData.pest_info)
      setValue('disease_info', plantData.disease_info)
      plantData.image_url?.forEach((image_url) => {
          append({id: image_url?.key, bucket:image_url?.bucket, region:image_url?.region, key:image_url?.key})
      })
      
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Get Plant by ID.");
  } finally {
    dispatch(updateLoading(false));
  }
};
