import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { Dispatch, DispatchWithoutAction, SetStateAction } from "react";
import { toast } from 'react-toastify';

import { deleteDisease } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { DeleteDiseaseMutation, Disease } from "../../../types/api";

interface deleteDiseaseProp {
  dispatch: AppDispatch;
  updateDiseases: Dispatch<SetStateAction<(Disease | null)[]>>;

  id: string | undefined;
  index: number;
  forceUpdate: DispatchWithoutAction;
}

export const deleteDiseaseById: ({
  dispatch,
  updateDiseases,
  id,
  index,
  forceUpdate
}: deleteDiseaseProp) => Promise<void> = async ({
  dispatch,
  updateDiseases,
  id,
  index,
  forceUpdate
}: deleteDiseaseProp) => {
  try {
    dispatch(updateLoading(true));
    const deleteDiseaseResponse: GraphQLResult<DeleteDiseaseMutation> =
      await API.graphql({ query: deleteDisease, variables: { input: { id } } }) as GraphQLResult<DeleteDiseaseMutation>;
    if (deleteDiseaseResponse.data?.deleteDisease) {
      updateDiseases((prevState) => {
        prevState.splice(index, 1);
        return prevState;
      });
      forceUpdate();
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Delete Disease.");
  } finally {
    dispatch(updateLoading(false));
  }
};
