import React, {
  FunctionComponent,
  useEffect,
  useReducer,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TableComponent } from "../../components/Table";
import { deletePestById, getPestDetailList } from "../../services";
import {Pest} from "../../types/api"

export const PestDashboard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [pests, updatePests] = useState<Array<Pest|null>>([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    getPestDetailList({ dispatch, updatePests });
  }, [dispatch]);

  const navigate = useNavigate();
  const colDisplay = ["name", "description", "image_url"];
  const editFunc = (id: string | undefined) => {
    navigate(`/pest/${id}`);
  };
  const deleteFunc = async (id: string | undefined, index: number) => {
    deletePestById({ id, index, dispatch, updatePests, forceUpdate });
  };
  const createFunc = () => {
    navigate("/pest/create");
  };

  return (
    <TableComponent
      dataInput={pests}
      colDisplay={colDisplay}
      createFunc={createFunc}
      editFunc={editFunc}
      deleteFunc={deleteFunc}
    />
  );
};
