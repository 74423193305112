import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AuthComponent } from "../../components/Auth";
import { RootState } from "../../redux/store";
import { checkUser } from "../../services";

interface AuthMiddlewareProp {
  children: ReactNode;
}
export const AuthMiddleware: FunctionComponent<AuthMiddlewareProp> = ({
  children
}) => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    checkUser({ dispatch });
  });
  return (
    <>
      {!loggedIn && <AuthComponent />}
      {loggedIn && children}
    </>
  );
};
