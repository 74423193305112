import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  updateLeftSidebarOpen,
  updateProfileDropdownOpen
} from "../../redux/slice/appSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { logoutService } from "../../services/api/auth/logout";

export const Navbar: FunctionComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { profileDropdownOpen, leftSidebarOpen } = useSelector(
    (state: RootState) => state.app
  );
  const logout = async () => {
    logoutService({dispatch});
  };
  return (
    <header className="flex items-center justify-between px-6 py-4 bg-green-100 border-b-4 border-green-600">
      <div className="flex items-center">
        <button
          onClick={() => dispatch(updateLeftSidebarOpen(!leftSidebarOpen))}
          className="text-gray-500 focus:outline-none"
          type="button"
        >
          <svg
            className="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6H20M4 12H20M4 18H11"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      <div className="flex items-center">
        <div className="relative">
          <button
            onClick={() =>
              dispatch(updateProfileDropdownOpen(!profileDropdownOpen))
            }
            className="relative block w-8 h-8 overflow-hidden rounded-full shadow focus:outline-none"
            type="button"
          >
            <img
              className="object-cover w-full h-full"
              src="https://images.unsplash.com/photo-1528892952291-009c663ce843?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=296&amp;q=80"
              alt="Your avatar"
            />
          </button>

          <div
            onClick={() =>
              dispatch(updateProfileDropdownOpen(!profileDropdownOpen))
            }
            className={`fixed inset-0 h-screen w-screen z-30 bg-black opacity-50 ${
              profileDropdownOpen ? "block" : "hidden"
            }`}
            onKeyDown={() =>
              dispatch(updateProfileDropdownOpen(!profileDropdownOpen))
            }
            role="button"
            tabIndex={-1}
            aria-label="Close profile dropdown"
          />

          <div
            className={`absolute right-0 mt-2 w-48 bg-white rounded-md overflow-hidden shadow-xl z-40 ${
              profileDropdownOpen ? "block" : "hidden"
            }`}
          >
            <div
              onClick={() => {
                logout();
              }}
              onKeyDown={() => {
                logout();
              }}
              role="button"
              tabIndex={-1}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-600 hover:text-white"
            >
              Logout
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
