import React, { FunctionComponent, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getContactFormDetail, updateContactFormDetail } from "../../services";
import { UpdateContactFormInput } from "../../types/api";
import { ContactFormForm } from "./form";

export const ContactFormDetailWithID: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();

  const { id } = useParams();
  useEffect(() => {
    getContactFormDetail({ id, setValue, dispatch });
  }, [id, setValue, dispatch]);

  const onSubmit = async (data: FieldValues) => {
    const contactFormData: UpdateContactFormInput = {
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message,
      status: data.status
    };
    updateContactFormDetail({ dispatch, contactFormData });
  };

  return (
    <ContactFormForm
      onSubmit={onSubmit}
      register={register}
      handleSubmit={handleSubmit}
      created
    />
  );
};
