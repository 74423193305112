import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AmplifyAuthStatus } from "../../types/AmplifyAuthStatus";

export interface AuthState {
  loggedIn: boolean;

  authStatus: string;
}

const initialState: AuthState = {
  loggedIn: false,

  authStatus: AmplifyAuthStatus.signIn
};

export const appSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    updateAuthStatus: (state, action: PayloadAction<string>) => {
      state.authStatus = action.payload;
    }
  }
});

export const { updateLoggedIn, updateAuthStatus } = appSlice.actions;

export default appSlice.reducer;
