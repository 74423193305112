import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { Dispatch, DispatchWithoutAction, SetStateAction } from "react";
import { toast } from 'react-toastify';

import { deleteContactForm } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { ContactForm,DeleteContactFormMutation } from "../../../types/api";

interface deleteContactFormProp {
  dispatch: AppDispatch;
  updateContactForms: Dispatch<SetStateAction<(ContactForm | null)[]>>;

  id: string | undefined;
  index: number;
  forceUpdate: DispatchWithoutAction;
}

export const deleteContactFormById: ({
  dispatch,
  updateContactForms,
  id,
  index,
  forceUpdate
}: deleteContactFormProp) => Promise<void> = async ({
  dispatch,
  updateContactForms,
  id,
  index,
  forceUpdate
}: deleteContactFormProp) => {
  try {
    dispatch(updateLoading(true));
    const deleteContactFormResponse: GraphQLResult<DeleteContactFormMutation> =
      await API.graphql({ query: deleteContactForm, variables: { input: { id } } }) as GraphQLResult<DeleteContactFormMutation>;
    if (deleteContactFormResponse.data?.deleteContactForm) {
      updateContactForms((prevState) => {
        prevState.splice(index, 1);
        return prevState;
      });
      forceUpdate();
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Delete ContactForm.");
  } finally {
    dispatch(updateLoading(false));
  }
};
