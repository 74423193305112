import { Storage } from "aws-amplify";
import { ChangeEvent } from "react";
import { FieldArrayMethodProps } from "react-hook-form";
import { toast } from 'react-toastify';
import { v4 as uuid } from "uuid";

import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";

interface removeImageFromS3Prop {
  key: string;
  index: number;
  dispatch: AppDispatch;
  image_remove: (index?: number | number[] | undefined) => void;
}

interface swapImagePositionProp {
  index: number;
  pos: number;
  image_swap: (indexA: number, indexB: number) => void;
}

interface uploadImageToS3Prop {
  bucket: string;
  region: string;
  event: ChangeEvent<HTMLInputElement>;
  image_append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  dispatch: AppDispatch;
}

export const removeImageFromS3: ({
  key,
  index,
  dispatch,
  image_remove
}: removeImageFromS3Prop) => Promise<void> = async ({
  key,
  index,
  dispatch,
  image_remove
}: removeImageFromS3Prop) => {
  try {
    dispatch(updateLoading(true));
    image_remove(index);
    const removeResult = await Storage.remove(key);
    if (removeResult) {
      toast.dark("Successfully remove the image");
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Remove uploaded image from S3.");
  } finally {
    dispatch(updateLoading(false));
  }
};

export const swapImagePosition: ({
  index,
  pos,
  image_swap
}: swapImagePositionProp) => void = ({
  index,
  pos,
  image_swap
}: swapImagePositionProp) => {
  image_swap(index, index + pos);
};

export const uploadImageToS3: ({
  bucket,
  region,
  event,
  image_append,
  dispatch
}: uploadImageToS3Prop) => Promise<void> = async ({
  bucket,
  region,
  event,
  image_append,
  dispatch
}: uploadImageToS3Prop) => {
  dispatch(updateLoading(true));
  event.persist();
  if (!event || !event.target || !event.target.files) {
    toast.dark("Please select your file and try again");
  }
  if (event.target.files) {
    Array.from(event.target.files).forEach(async (file) => {
      try {
        const filename = file.name
          .toLowerCase()
          .replace(/ /g, "-")
          .replace(/[^\w-]+/g, "");
        const fileExtension = file.name.split(".").pop();
        // Define the image name
        const mainImgName = `${filename}-${uuid()}.${fileExtension}`;

        // Push the image to S3
        if (file === null) return;
        const storageResult = await Storage.put(mainImgName, file, {
          contentType: "image/*",
          level: "public"
        });
        if (storageResult) {
          toast.dark(storageResult);
          image_append({
            bucket,
            region,
            key: storageResult.key,
            id: storageResult.key
          });
        }
      } catch (err) {
        // console.log(err)
        toast.dark("Error: Upload image to S3.");
      } finally {
        dispatch(updateLoading(false));
      }
    });
  }
};
