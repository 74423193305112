import { IconType } from "@react-icons/all-files";
import { FaUsersCog } from "@react-icons/all-files/fa/FaUsersCog";

interface navLinksComponent {
  name: string;
  path: string;
  Icon: IconType;
}
export const customerRelationLinks: navLinksComponent[] = [
  {
    name: "Contact Form",
    path: "/contact-form",
    Icon: FaUsersCog
  }
];
