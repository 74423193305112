import React, { FunctionComponent, useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {updateLoading} from "../../redux/slice/appSlice"
import { createRackDetail } from "../../services";
import { CreateRackInput } from "../../types/api";
import { RackForm } from "./form";

export const RackCreate: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm();
  const { fields, append, swap, remove } = useFieldArray({
    name: "image_url",
    control
  });
  useEffect(() => {
    dispatch(updateLoading(false));
  }, [dispatch]);
  const onSubmit = async (data: FieldValues) => {
    const rackData: CreateRackInput = {
      name: data.name,
      description: data.description,
      price: data.price,
      image_url: data.image_url
    };
    createRackDetail({ rackData, dispatch, navigate });
  };
  return (
    <RackForm
      onSubmit={onSubmit}
      register={register}
      handleSubmit={handleSubmit}
      image_field={fields}
      image_append={append}
      image_swap={swap}
      image_remove={remove}
    />
  );
};
