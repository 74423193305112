import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';

import { updateNutrient } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  UpdateNutrientInput,
  UpdateNutrientMutation
} from "../../../types/api";

interface updateNutrientDetailProp {
  dispatch: AppDispatch;
  nutrientData: UpdateNutrientInput;
}
export const updateNutrientDetail: ({
  dispatch,
  nutrientData
}: updateNutrientDetailProp) => Promise<void> = async ({
  dispatch,
  nutrientData
}: updateNutrientDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const updateResponse: GraphQLResult<UpdateNutrientMutation> =
      await API.graphql({
        query: updateNutrient,
        variables: { input: nutrientData }
      }) as GraphQLResult<UpdateNutrientMutation>;
    if (updateResponse && updateResponse.data?.updateNutrient) {
      toast.dark("Successfully updated nutrient");
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Update nutrient.");
  } finally {
    dispatch(updateLoading(false));
  }
};
