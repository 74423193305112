import React, { FunctionComponent } from "react";
import { Route, Routes as Switch } from "react-router-dom";

import {
  ContactFormDashboard,
  ContactFormDetailWithID,
  Dashboard,
  DiseaseCreate,
  DiseaseDashboard,
  DiseaseDetailWithID,
  Error404,
  NutrientCreate,
  NutrientDashboard,
  NutrientDetailWithID,
  NutrientTerminologyCreate,
  NutrientTerminologyDashboard,
  NutrientTerminologyDetailWithID,
  PestCreate,
  PestDashboard,
  PestDetailWithID,
  PlantCreate,
  PlantDashboard,
  PlantDetailWithID,
  RackCreate,
  RackDashboard,
  RackDetailWithID
} from "../pages";

export const Routes: FunctionComponent = () => (
  <>
    <Switch>
      <Route path="/" element={<Dashboard />} />
      <Route path="/contact-form" element={<ContactFormDashboard />} />
      <Route path="/contact-form/:id" element={<ContactFormDetailWithID />} />
      <Route path="/plant" element={<PlantDashboard />} />
      <Route path="/plant/create" element={<PlantCreate />} />
      <Route path="/plant/:id" element={<PlantDetailWithID />} />
      <Route path="/disease" element={<DiseaseDashboard />} />
      <Route path="/disease/create" element={<DiseaseCreate />} />
      <Route path="/disease/:id" element={<DiseaseDetailWithID />} />
      <Route path="/rack" element={<RackDashboard />} />
      <Route path="/rack/create" element={<RackCreate />} />
      <Route path="/rack/:id" element={<RackDetailWithID />} />
      <Route path="/pest" element={<PestDashboard />} />
      <Route path="/pest/create" element={<PestCreate />} />
      <Route path="/pest/:id" element={<PestDetailWithID />} />
      <Route path="/nutrient" element={<NutrientDashboard />} />
      <Route path="/nutrient/create" element={<NutrientCreate />} />
      <Route path="/nutrient/:id" element={<NutrientDetailWithID />} />
      <Route
        path="/nutrient-terminology"
        element={<NutrientTerminologyDashboard />}
      />
      <Route
        path="/nutrient-terminology/create"
        element={<NutrientTerminologyCreate />}
      />
      <Route
        path="/nutrient-terminology/:id"
        element={<NutrientTerminologyDetailWithID />}
      />
      <Route path="*" element={<Error404 />} />
    </Switch>
  </>
);
