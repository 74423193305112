import React, { FunctionComponent } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface RangeInputProp {
  label: string;
  min_register: UseFormRegisterReturn;
  max_register: UseFormRegisterReturn;
}
export const RangeInput: FunctionComponent<RangeInputProp> = ({
  label,
  min_register,
  max_register
}) => (
  <>
    <div>{label}</div>
    <div className="flex flex-row justify-between w-full">
      <div className="w-1/2">
        <label className="pr-4 text-sm lg:pr-8" htmlFor={min_register.name}>
          Min:
          <input
            className="w-2/3 p-2 mt-3 ml-4 border border-gray-300 rounded outline-none lg:ml-8 focus:shadow focus:border-green-600"
            type="number"
            min="0"
            max="999999"
            step=".01"
            name={min_register.name}
            onChange={min_register.onChange}
            onBlur={min_register.onBlur}
            ref={min_register.ref}
          />
        </label>
      </div>
      <div className="w-1/2 text-right">
        <label className="text-sm" htmlFor={max_register.name}>
          Max:
          <input
            className="w-2/3 p-2 mt-3 ml-4 border border-gray-300 rounded outline-none lg:ml-8 focus:shadow focus:border-green-600"
            type="number"
            min="0"
            max="999999"
            step=".01"
            name={max_register.name}
            onChange={max_register.onChange}
            onBlur={max_register.onBlur}
            ref={max_register.ref}
          />
        </label>
      </div>
    </div>
  </>
);
