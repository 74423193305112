import React, { FunctionComponent } from "react";

import { customerRelationLinks } from "./data";
import { NavLinkComponent } from "./NavLink";

export const CustomerRelationLinks: FunctionComponent = () => (
  <>
    {customerRelationLinks.map((link) => (
      <NavLinkComponent key={link.name} name={link.name} path={link.path}>
        <link.Icon />
      </NavLinkComponent>
    ))}
  </>
);
