import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { NavigateFunction } from "react-router-dom";
import { toast } from 'react-toastify';

import { createRack } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  CreateRackInput,
  CreateRackMutation
} from "../../../types/api";

interface createRackDetailProp {
  rackData: CreateRackInput;
  dispatch: AppDispatch;
  navigate: NavigateFunction;
}

export const createRackDetail: ({
  rackData,
  dispatch,
  navigate
}: createRackDetailProp) => Promise<void> = async ({
  rackData,
  dispatch,
  navigate
}: createRackDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const createResponse: GraphQLResult<CreateRackMutation> =
      await API.graphql({
        query: createRack,
        variables: { input: rackData }
      }) as GraphQLResult<CreateRackMutation>;
    if (createResponse && createResponse.data?.createRack) {
      navigate(`/rack/${createResponse.data.createRack.id}`);
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Create Rack.");
  } finally {
    dispatch(updateLoading(false));
  }
};
