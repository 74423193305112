import React, { FunctionComponent } from "react";
import { ToastContainer } from "react-toastify";

import { DefaultLayout } from "./layout";
import { AppMiddleware } from "./middlewares";
import { Routes } from "./routes";

const App: FunctionComponent = () => (
  <>
    <ToastContainer
      toastClassName="toastify-wrapper"
      bodyClassName="toastify-body"
      progressClassName="toastify-progress"
      autoClose={2000}
      position="top-center"
      draggablePercent={40}
    />
    <AppMiddleware>
      <DefaultLayout>
        <Routes />
      </DefaultLayout>
    </AppMiddleware>
  </>
);

export default App;
