import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';

import { updateLoading } from "../../../redux/slice/appSlice";
import { updateLoggedIn } from "../../../redux/slice/authSlice";
import { AppDispatch } from "../../../redux/store";

interface LoginServiceProp {
  dispatch: AppDispatch;
  username: string;
  password: string;
}

export const loginService: ({
  dispatch,
  username,
  password
}: LoginServiceProp) => Promise<void> = async ({
  dispatch,
  username,
  password
}: LoginServiceProp) => {
  dispatch(updateLoading(true));
  try {
    const loginResult = await Auth.signIn(username, password);
    if (loginResult && loginResult.signInUserSession.accessToken.payload["cognito:groups"] && loginResult.signInUserSession.accessToken.payload["cognito:groups"].includes("admin")) {
      dispatch(updateLoggedIn(true));
    } else {
      toast.dark("Error: User doesn't have permission.")
      dispatch(updateLoading(false));
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: User sign in.");
    dispatch(updateLoading(false));
  }
};
