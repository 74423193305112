import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";

export const LeftSidebarHeader: FunctionComponent = () => (
  <NavLink to="/" className="flex items-center">
    <svg
      className="w-12 h-12"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 900.000000 528.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.10, written by Peter Selinger 2001-2011
      </metadata>
      <g
        transform="translate(0.000000,528.000000) scale(0.100000,-0.100000)"
        fill="#5b635b"
        stroke="none"
      >
        <path
          d="M360 4662 c-6 -2 -10 -179 -10 -503 l0 -499 333 -2 332 -3 5 -1650 5
-1650 633 -3 632 -2 0 2155 0 2155 -960 2 c-528 2 -965 2 -970 0z"
        />
      </g>
      <g
        transform="translate(0.000000,528.000000) scale(0.100000,-0.100000)"
        fill="#83a343"
        stroke="none"
      >
        <path
          d="M7645 5008 c-16 -6 -52 -14 -80 -18 -62 -9 -138 -36 -218 -76 -16 -8
-33 -14 -39 -14 -6 0 -27 -13 -48 -30 -21 -16 -43 -30 -49 -30 -17 0 -221
-209 -221 -226 0 -8 8 -18 18 -21 15 -6 98 -65 176 -126 16 -12 34 -24 40 -26
6 -2 55 -37 108 -78 54 -40 101 -73 104 -73 3 0 29 20 57 45 29 25 57 45 63
45 7 0 14 7 18 15 3 8 14 15 24 15 10 0 27 7 38 15 14 10 47 14 120 15 l100 0
41 -35 c74 -63 102 -154 69 -222 -8 -15 -24 -48 -35 -73 -45 -94 -18 -64 -550
-610 -158 -162 -306 -316 -329 -341 l-42 -46 7 -207 6 -206 801 2 801 3 3 272
2 273 -347 2 -348 3 -3 30 c-2 27 12 43 150 170 84 77 196 184 250 237 92 95
198 234 198 262 0 7 7 19 15 26 8 7 15 22 15 34 0 11 7 30 15 40 8 11 15 36
15 57 0 20 7 50 15 65 21 41 21 252 0 280 -8 10 -15 35 -15 55 0 19 -7 44 -15
55 -8 10 -15 29 -15 40 0 12 -7 27 -15 34 -8 7 -15 17 -15 23 0 12 -108 147
-118 147 -4 0 -25 16 -47 36 -21 20 -54 43 -71 50 -18 8 -35 18 -39 24 -3 5
-14 10 -24 10 -10 0 -24 7 -31 15 -7 8 -24 15 -39 15 -14 0 -35 7 -45 15 -11
8 -34 15 -50 15 -17 0 -53 7 -80 15 -57 17 -293 19 -341 3z"
        />
        <path
          d="M2678 4653 c-2 -5 0 -163 3 -353 4 -190 10 -583 14 -875 5 -409 10
-537 20 -560 50 -112 78 -172 86 -185 5 -8 12 -22 15 -30 11 -29 37 -85 46
-100 4 -8 11 -22 14 -30 3 -8 10 -22 15 -30 5 -8 12 -22 15 -30 11 -29 37 -85
46 -100 4 -8 11 -22 14 -30 3 -8 12 -27 20 -42 8 -14 14 -33 14 -41 0 -8 7
-20 15 -27 8 -7 15 -20 15 -30 0 -10 7 -23 15 -30 8 -7 15 -23 15 -35 0 -12 7
-28 15 -35 8 -7 15 -20 15 -30 0 -10 7 -23 15 -30 8 -7 15 -17 15 -24 0 -6 14
-40 31 -76 17 -36 37 -81 46 -100 8 -19 19 -42 24 -50 5 -8 12 -22 15 -30 3
-8 18 -42 33 -75 16 -33 43 -91 61 -130 65 -144 92 -200 101 -215 5 -8 12 -22
15 -30 12 -29 134 -290 185 -395 16 -33 29 -67 29 -76 0 -9 8 -23 18 -32 17
-15 60 -17 408 -17 l389 0 33 73 c18 39 38 79 43 87 5 8 12 22 15 30 10 27 36
85 45 100 5 8 12 22 15 30 12 34 89 203 110 242 8 14 14 33 14 41 0 8 7 17 15
21 8 3 15 16 15 29 0 14 7 30 15 37 8 7 15 18 15 25 0 12 18 52 42 95 4 8 11
22 14 30 10 27 36 85 45 100 5 8 12 22 15 30 3 8 12 27 20 42 8 14 14 33 14
41 0 8 7 20 15 27 8 7 15 20 15 30 0 10 7 23 15 30 8 7 15 23 15 35 0 12 7 28
15 35 8 7 15 19 15 27 0 13 15 48 41 93 5 8 12 22 15 30 3 8 19 42 34 75 15
33 38 83 50 110 13 28 47 100 76 161 30 61 54 118 54 126 0 9 7 21 15 28 8 7
15 20 15 30 0 10 7 23 15 30 8 7 15 20 15 29 0 10 14 44 30 76 17 32 30 67 30
77 0 20 44 42 67 34 11 -4 13 -230 13 -1281 l0 -1275 543 2 542 3 -1 2145 -2
2145 -671 3 -671 2 -10 -24 c-5 -13 -14 -27 -19 -31 -6 -3 -11 -17 -11 -29 0
-13 -7 -29 -15 -36 -8 -7 -15 -20 -15 -30 0 -10 -7 -23 -15 -30 -8 -7 -15 -20
-15 -30 0 -10 -7 -23 -15 -30 -8 -7 -15 -20 -15 -30 0 -10 -7 -23 -15 -30 -8
-7 -15 -20 -15 -30 0 -10 -7 -23 -15 -30 -8 -7 -15 -17 -15 -23 0 -6 -20 -53
-45 -104 -25 -51 -66 -137 -91 -190 -25 -54 -50 -105 -55 -113 -5 -8 -12 -22
-15 -30 -3 -8 -10 -22 -15 -30 -5 -8 -12 -22 -15 -30 -3 -8 -10 -22 -15 -30
-5 -8 -12 -22 -15 -30 -9 -24 -65 -144 -75 -160 -5 -8 -12 -22 -15 -30 -3 -8
-10 -22 -15 -30 -5 -8 -12 -22 -15 -30 -3 -8 -10 -22 -15 -30 -5 -8 -12 -22
-15 -30 -20 -48 -62 -137 -105 -224 -27 -54 -49 -104 -49 -111 0 -7 -7 -18
-15 -25 -8 -7 -15 -20 -15 -30 0 -10 -7 -23 -15 -30 -8 -7 -15 -20 -15 -30 0
-10 -7 -23 -15 -30 -8 -7 -15 -20 -15 -30 0 -10 -7 -23 -15 -30 -8 -7 -15 -23
-15 -35 0 -12 -7 -28 -15 -35 -8 -7 -15 -20 -15 -30 0 -10 -7 -20 -15 -24 -8
-3 -15 -15 -15 -27 0 -13 -6 -30 -13 -38 -7 -9 -21 -36 -31 -61 -10 -25 -22
-52 -27 -60 -9 -17 -37 -73 -69 -140 -11 -25 -25 -52 -29 -60 -12 -22 -38 -83
-45 -102 -11 -35 -76 -13 -76 26 0 13 -7 29 -15 36 -8 7 -15 20 -15 30 0 10
-7 23 -15 30 -8 7 -15 19 -15 27 0 13 -15 48 -41 93 -5 8 -12 22 -15 30 -3 8
-10 22 -15 30 -5 8 -12 22 -15 30 -3 8 -10 22 -15 30 -5 8 -12 22 -15 30 -9
24 -36 84 -45 100 -9 18 -64 130 -125 256 -24 49 -44 95 -44 101 0 6 -7 16
-15 23 -8 7 -15 20 -15 30 0 10 -7 23 -15 30 -8 7 -15 23 -15 37 0 13 -7 26
-15 29 -8 4 -15 12 -15 19 0 12 -17 51 -42 95 -4 8 -11 22 -14 30 -3 8 -10 22
-15 30 -5 8 -12 22 -15 30 -3 8 -10 22 -15 30 -5 8 -12 22 -15 30 -3 8 -10 22
-15 30 -5 8 -12 22 -14 30 -7 17 -63 137 -76 160 -21 37 -49 104 -49 116 0 7
-4 15 -9 18 -5 4 -19 27 -31 54 -12 26 -26 54 -31 62 -5 8 -12 22 -15 30 -21
50 -47 101 -55 106 -5 3 -9 16 -9 28 0 13 -7 29 -15 36 -8 7 -15 20 -15 30 0
10 -7 23 -15 30 -8 7 -15 20 -15 30 0 10 -7 23 -15 30 -8 7 -15 20 -15 30 0
10 -7 23 -15 30 -8 7 -15 20 -15 30 0 10 -7 23 -15 30 -8 7 -15 19 -15 26 0 8
-14 42 -31 76 -18 35 -39 78 -48 96 l-16 32 -102 0 c-56 0 -103 -3 -105 -7z"
        />
      </g>
    </svg>
    <span className="mx-2 text-2xl font-semibold text-white">Dashboard</span>
  </NavLink>
);
