import React, { FunctionComponent } from "react";
import {
  FieldArrayMethodProps,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister
} from "react-hook-form";

import { ImageUpload, RangeInput, TextInput } from "../../components/Form";
import { CreatePlantInput } from "../../types/api";

interface PlantFormProp {
  onSubmit: (data: CreatePlantInput) => void;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  image_field: Record<"id", string>[];
  image_append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  image_swap: (indexA: number, indexB: number) => void;
  image_remove: (index?: number | number[] | undefined) => void;
  created?: boolean;
}
export const PlantForm: FunctionComponent<PlantFormProp> = ({
  onSubmit,
  register,
  handleSubmit,
  image_field,
  image_append,
  image_swap,
  image_remove,
  created
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className="flex flex-row justify-center align-middle">
      <div className="w-full mb-6 md:w-3/5">
        <div className="text-right">
          <button
            className="px-4 py-2 my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
        <div className="mb-6">
          <TextInput label="Name" register={register("name")} />
        </div>

        <div className="mb-6">
          <TextInput label="Type" register={register("type")} />
        </div>

        <div className="mb-6">
          <TextInput
            label="Scientific Name"
            register={register("scientific_name")}
          />
        </div>

        <div className="mb-6">
          <TextInput label="Name (BM)" register={register("name_bm")} />
        </div>

        <div className="mb-6">
          <TextInput label="Name (CN)" register={register("name_cn")} />
        </div>

        <div className="mb-6">
          <RangeInput
            label="Temperature"
            min_register={register("temperature_min")}
            max_register={register("temperature_max")}
          />
          <div className="mt-2">
            <TextInput
              label="Scale of Unit"
              register={register("temperature_scale_unit")}
            />
          </div>
        </div>

        <div className="mb-6">
          <RangeInput
            label="Height"
            min_register={register("height_min")}
            max_register={register("height_max")}
          />
          <div className="mt-2">
            <TextInput
              label="Scale of Unit"
              register={register("height_scale_unit")}
            />
          </div>
        </div>

        <div className="mb-6">
          <RangeInput
            label="Seed Planting Depth"
            min_register={register("seed_planting_depth_min")}
            max_register={register("seed_planting_depth_max")}
          />
          <div className="mt-2">
            <TextInput
              label="Scale of Unit"
              register={register("seed_planting_depth_scale_unit")}
            />
          </div>
        </div>

        <div className="mb-6">
          <RangeInput
            label="Planting Distance Between Plants"
            min_register={register("planting_distance_between_plants_min")}
            max_register={register("planting_distance_between_plants_max")}
          />
          <div className="mt-2">
            <TextInput
              label="Scale of Unit"
              register={register("planting_distance_between_plants_scale_unit")}
            />
          </div>
        </div>

        <div className="mb-6">
          <TextInput
            label="Pruning Requirement"
            register={register("pruning_requirement")}
          />
        </div>

        <div className="mb-6">
          <TextInput
            label="Soil Moisture"
            register={register("soil_moisture")}
          />
        </div>

        <div className="mb-6">
          <RangeInput
            label="Sunlight"
            min_register={register("sunlight_min")}
            max_register={register("sunlight_max")}
          />
          <div className="mt-2">
            <TextInput
              label="Scale of Unit"
              register={register("sunlight_scale_unit")}
            />
          </div>
        </div>

        <div className="mb-6">
          <RangeInput
            label="Germination"
            min_register={register("germination_min")}
            max_register={register("germination_max")}
          />
          <div className="mt-2">
            <TextInput
              label="Scale of Unit"
              register={register("germination_scale_unit")}
            />
          </div>
        </div>

        <div className="mb-6">
          <RangeInput
            label="Harvest Day"
            min_register={register("harvest_day_min")}
            max_register={register("harvest_day_max")}
          />
          <div className="mt-2">
            <TextInput
              label="Scale of Unit"
              register={register("harvest_day_scale_unit")}
            />
          </div>
        </div>

        <div className="mb-6">
          <RangeInput
            label="Harvest Month"
            min_register={register("harvest_month_min")}
            max_register={register("harvest_month_max")}
          />
          <div className="mt-2">
            <TextInput
              label="Scale of Unit"
              register={register("harvest_month_scale_unit")}
            />
          </div>
        </div>

        <div className="mb-6">
          <TextInput label="Water Amount" register={register("water_amount")} />
          {/* <RangeInput
              label="Water Amount"
              min_register={register("water_amount_min")}
              max_register={register("water_amount_max")}
            />
            <div className="mt-2">
              <TextInput
                label="Scale of Unit"
                register={register("water_amount_scale_unit")}
              />
            </div> */}
        </div>

        <div className="mb-6">
          <TextInput label="Plant Nature" register={register("plant_nature")} />
        </div>

        <div className="mb-6">
          <TextInput
            label="Watering Frequency"
            register={register("watering_frequency")}
          />
        </div>

        <div className="mb-6">
          <TextInput
            label="Watering Duration"
            register={register("watering_duration")}
          />
        </div>

        <div className="mb-6">
          <TextInput label="Pest Info" register={register("pest_info")} />
        </div>

        <div className="mb-6">
          <TextInput label="Disease Info" register={register("disease_info")} />
        </div>

        <ImageUpload
          image_field={image_field}
          image_append={image_append}
          image_swap={image_swap}
          image_remove={image_remove}
        />
        <div className="w-full text-center">
          <button
            className="px-4 py-2 mx-auto my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  </form>
);

PlantForm.defaultProps = {
  created: false
};
