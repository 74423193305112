import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateLeftSidebarOpen } from "../../redux/slice/appSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { ContentManagementLinks } from "./ContentManagementLinks";
import {CustomerRelationLinks} from "./CustomerRelationLinks"
import { LeftSidebarHeader } from "./LeftSidebarHeader";
import { NavCategory } from "./NavCategory";

export const LeftSidebar: FunctionComponent = () => {
  const leftSidebarOpen = useSelector(
    (state: RootState) => state.app.leftSidebarOpen
  );
  const dispatch = useDispatch<AppDispatch>();
  return (
    <>
      <div
        className={`fixed z-20 inset-0 bg-black opacity-50 transition-opacity ${
          leftSidebarOpen ? "block" : "hidden"
        }`}
        onClick={() => dispatch(updateLeftSidebarOpen(false))}
        onKeyDown={() => dispatch(updateLeftSidebarOpen(false))}
        role="button"
        tabIndex={-1}
        aria-label="Close left sidebar"
      />
      <div
        className={`fixed z-30 inset-y-0 left-0 w-64 transition duration-300 transform bg-green-900 overflow-y-auto ${
          leftSidebarOpen
            ? "translate-x-0 ease-out"
            : "-translate-x-full ease-in"
        }`}
      >
        <div className="flex items-center justify-center my-4">
          <LeftSidebarHeader />
        </div>
        <nav className="my-4">
          <NavCategory name="Content Management" />
          <ContentManagementLinks />
        </nav>
        <nav className="my-4">
          <NavCategory name="Customer Relations" />
          <CustomerRelationLinks />
        </nav>
      </div>
    </>
  );
};
