import React, { FunctionComponent } from "react";
import ReactQuill from "react-quill";

interface TextInputProp {
  label: string;
  value: string;
  onChange: (state: string) => void;
}

export const AdvancedTextInput: FunctionComponent<TextInputProp> = ({
  label,
  value,
  onChange
}) => (
  <>
    <div>{label}:</div>
    <ReactQuill
      theme="snow"
      value={value || ''}
      className="w-full p-2 mt-3 border border-gray-300 rounded outline-none focus:shadow focus:border-green-600"
      onChange={onChange}
    />
  </>
);
