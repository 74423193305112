import React, { FunctionComponent } from "react";
import {
  FieldArrayMethodProps,
  FieldValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormWatch
} from "react-hook-form";

import { AdvancedTextInput, ImageUpload } from "../../components/Form";
import { CreatePestInput } from "../../types/api";

interface PestFormProp {
  onSubmit: (data: CreatePestInput) => void;

  handleSubmit: UseFormHandleSubmit<FieldValues>;
  image_field: Record<"id", string>[];
  image_append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  image_swap: (indexA: number, indexB: number) => void;
  image_remove: (index?: number | number[] | undefined) => void;
  created?: boolean;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
}
export const PestForm: FunctionComponent<PestFormProp> = ({
  onSubmit,

  handleSubmit,
  image_field,
  image_append,
  image_swap,
  image_remove,
  created,
  setValue,
  watch
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className="flex flex-row justify-center align-middle">
      <div className="w-full mb-6 md:w-3/5">
        <div className="text-right">
          <button
            className="px-4 py-2 my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
        <div className="mb-6">
          <AdvancedTextInput
            label="Name"
            value={watch("name")}
            onChange={(state: string) => {
              setValue("name", state);
            }}
          />
        </div>

        <div className="mb-6">
          <AdvancedTextInput
            label="Description"
            value={watch("description")}
            onChange={(state: string) => {
              setValue("description", state);
            }}
          />
        </div>

        <div className="mb-6">
          <AdvancedTextInput
            label="Detection"
            value={watch("detection")}
            onChange={(state: string) => {
              setValue("detection", state);
            }}
          />
        </div>

        <div className="mb-6">
          <AdvancedTextInput
            label="Management"
            value={watch("management")}
            onChange={(state: string) => {
              setValue("management", state);
            }}
          />
        </div>

        <div className="mb-6">
          <AdvancedTextInput
            label="Notes"
            value={watch("notes")}
            onChange={(state: string) => {
              setValue("notes", state);
            }}
          />
        </div>

        <ImageUpload
          image_field={image_field}
          image_append={image_append}
          image_swap={image_swap}
          image_remove={image_remove}
        />
        <div className="w-full text-center">
          <button
            className="px-4 py-2 mx-auto my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  </form>
);

PestForm.defaultProps = {
  created: false
};
