import React, { FunctionComponent, useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateLoading } from "../../redux/slice/appSlice";
import { createNutrientDetail } from "../../services";
import { CreateNutrientInput } from "../../types/api";
import { NutrientForm } from "./form";

export const NutrientCreate: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm();
  const {
    fields: deficient_fields,
    append: deficient_append,
    swap: deficient_swap,
    remove: deficient_remove
  } = useFieldArray({
    name: "deficient_image",
    control
  });
  const {
    fields: toxic_fields,
    append: toxic_append,
    swap: toxic_swap,
    remove: toxic_remove
  } = useFieldArray({
    name: "toxic_image",
    control
  });

  useEffect(() => {
    dispatch(updateLoading(false));
  }, [dispatch]);
  const onSubmit = async (data: FieldValues) => {
    const nutrientData: CreateNutrientInput = {
      name: data.name,
      short_name: data.short_name,
      type: data.type,
      importance: data.importance,
      deficient_symptom: data.deficient_symptom,
      leaf_position: data.leaf_position,
      deficient_image: data.deficient_image,
      worsen: data.worsen,
      solution: data.solution,
      toxic_symptom: data.toxic_symptom,
      toxic_image: data.toxic_image
    };
    createNutrientDetail({ nutrientData, dispatch, navigate });
  };
  return (
    <NutrientForm
      onSubmit={onSubmit}
      register={register}
      handleSubmit={handleSubmit}
      deficient_fields={deficient_fields}
      deficient_append={deficient_append}
      deficient_swap={deficient_swap}
      deficient_remove={deficient_remove}
      toxic_fields={toxic_fields}
      toxic_append={toxic_append}
      toxic_swap={toxic_swap}
      toxic_remove={toxic_remove}
    />
  );
};
