// import { useHistory } from "react-router-dom";
// import { deleteNutrient, listNutrients } from "../../api";
import React, {
  FunctionComponent,
  useEffect,
  useReducer,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TableComponent } from "../../components/Table";
import { deleteNutrientById, getNutrientDetailList } from "../../services";
import {Nutrient} from "../../types/api"

export const NutrientDashboard: FunctionComponent = () => {
  const [nutrients, updateNutrients] = useState<Array<Nutrient|null>>([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const dispatch = useDispatch();
  useEffect(() => {
    getNutrientDetailList({ dispatch, updateNutrients });
  }, [dispatch]);

  const navigate = useNavigate();
  const colDisplay = ["name", "type", "image_url"];
  const editFunc = (id: string | undefined) => {
    navigate(`/nutrient/${id}`);
  };
  const deleteFunc = async (id: string | undefined, index: number) => {
    deleteNutrientById({ id, index, dispatch, updateNutrients, forceUpdate });
  };
  const createFunc = () => {
    navigate("/nutrient/create");
  };

  return (
    <TableComponent
      dataInput={nutrients}
      colDisplay={colDisplay}
      createFunc={createFunc}
      editFunc={editFunc}
      deleteFunc={deleteFunc}
    />
  );
};
