import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { Dispatch,SetStateAction } from "react";
import { toast } from 'react-toastify';

import { listPlants } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { ListPlantsQuery, Plant } from "../../../types/api";

interface getPlantDetailListProp {
  dispatch: AppDispatch;
  updatePlants: Dispatch<SetStateAction<(Plant|null)[]>>;
}
export const getPlantDetailList: ({
  dispatch,
  updatePlants
}: getPlantDetailListProp) => Promise<void> = async ({
  dispatch,
  updatePlants
}: getPlantDetailListProp) => {
  try {
    dispatch(updateLoading(true));
    const getResponse: GraphQLResult<ListPlantsQuery> = await API.graphql({
      query: listPlants
    }) as GraphQLResult<ListPlantsQuery>;
    if (getResponse.data?.listPlants?.items) {
        updatePlants(getResponse.data.listPlants.items);
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Get Plant List.");
  } finally {
    dispatch(updateLoading(false));
  }
};
