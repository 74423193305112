import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  FieldArrayMethodProps,
  FieldValues,
  UseFormSetValue
} from "react-hook-form";
import { toast } from 'react-toastify';

import { getNutrientTerminology } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { GetNutrientTerminologyQuery } from "../../../types/api";

interface getNutrientTerminologyDetailProps {
  dispatch: AppDispatch;
  setValue: UseFormSetValue<FieldValues>;
  append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  id: string | undefined;
}
export const getNutrientTerminologyDetail: ({
  dispatch,
  setValue,
  append,
  id
}: getNutrientTerminologyDetailProps) => Promise<void> = async ({
  dispatch,
  setValue,
  append,
  id
}: getNutrientTerminologyDetailProps) => {
  try {
    dispatch(updateLoading(true));
    const getResponse: GraphQLResult<GetNutrientTerminologyQuery> =
      await API.graphql({
        query: getNutrientTerminology,
        variables: { id }
      }) as GraphQLResult<GetNutrientTerminologyQuery>;
    if (getResponse.data?.getNutrientTerminology) {
      const nutrientTerminologyData =
        getResponse.data?.getNutrientTerminology;
      setValue("id", nutrientTerminologyData.id);
      setValue("name", nutrientTerminologyData.name);
      setValue("meaning", nutrientTerminologyData.meaning);
      nutrientTerminologyData.image_url?.forEach((image_url) => {
        append({
          id: image_url?.key,
          bucket: image_url?.bucket,
          region: image_url?.region,
          key: image_url?.key
        });
      });
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Get Nutrient Terminology by ID.");
  } finally {
    dispatch(updateLoading(false));
  }
};
