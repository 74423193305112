import React, { FunctionComponent, useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  getNutrientTerminologyDetail,
  updateNutrientTerminologyDetail
} from "../../services";
import { UpdateNutrientTerminologyInput } from "../../types/api";
import { NutrientTerminologyForm } from "./form";

export const NutrientTerminologyDetailWithID: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, control, setValue } = useForm();
  const { fields, append, swap, remove } = useFieldArray({
    name: "image_url",
    control
  });
  const { id } = useParams();
  useEffect(() => {
    getNutrientTerminologyDetail({ id, dispatch, setValue, append });
  }, [id, append, setValue, dispatch]);

  const onSubmit = async (data: FieldValues) => {
    const nutrientTerminologyData: UpdateNutrientTerminologyInput = {
      id: data.id,
      name: data.name,
      meaning: data.meaning,
      image_url: data.image_url
    };
    updateNutrientTerminologyDetail({ dispatch, nutrientTerminologyData });
  };

  return (
    <NutrientTerminologyForm
      onSubmit={onSubmit}
      register={register}
      handleSubmit={handleSubmit}
      image_field={fields}
      image_append={append}
      image_swap={swap}
      image_remove={remove}
      created
    />
  );
};
