import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';

import { updateLoading } from "../../../redux/slice/appSlice";
import { updateLoggedIn } from "../../../redux/slice/authSlice";
import { AppDispatch } from "../../../redux/store";

interface LogoutServiceProp {
  dispatch: AppDispatch;
}

export const logoutService: ({ dispatch }: LogoutServiceProp) => Promise<void> = async ({ dispatch }: LogoutServiceProp) => {
  dispatch(updateLoading(true));
  try {
    dispatch(updateLoading(true));
    const logoutResult = await Auth.signOut();
    if (logoutResult) {
      dispatch(updateLoggedIn(false));
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: User sign out.");
  } 
};
