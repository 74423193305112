import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppState {
  loading: boolean;
  leftSidebarOpen: boolean;
  profileDropdownOpen: boolean;
  title: string;
}

const initialState: AppState = {
  loading: false,
  leftSidebarOpen: false,
  profileDropdownOpen: false,
  title: "Dashboard"
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    updateLeftSidebarOpen: (
      state: AppState,
      action: PayloadAction<boolean>
    ) => {
      state.leftSidebarOpen = action.payload;
    },
    updateProfileDropdownOpen: (
      state: AppState,
      action: PayloadAction<boolean>
    ) => {
      state.profileDropdownOpen = action.payload;
    },
    updateTitle: (state: AppState, action: PayloadAction<string>) => {
      state.title = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  updateLoading,
  updateLeftSidebarOpen,
  updateProfileDropdownOpen,
  updateTitle
} = appSlice.actions;

export default appSlice.reducer;
