import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';

import { updateNutrientTerminology } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  UpdateNutrientTerminologyInput,
  UpdateNutrientTerminologyMutation
} from "../../../types/api";

interface updateNutrientTerminologyDetailProp {
  dispatch: AppDispatch;
  nutrientTerminologyData: UpdateNutrientTerminologyInput;
}
export const updateNutrientTerminologyDetail: ({
  dispatch,
  nutrientTerminologyData
}: updateNutrientTerminologyDetailProp) => Promise<void> = async ({
  dispatch,
  nutrientTerminologyData
}: updateNutrientTerminologyDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const updateResponse: GraphQLResult<UpdateNutrientTerminologyMutation> =
      await API.graphql({
        query: updateNutrientTerminology,
        variables: { input: nutrientTerminologyData }
      }) as GraphQLResult<UpdateNutrientTerminologyMutation>;
    if (updateResponse && updateResponse.data?.updateNutrientTerminology) {
      toast.dark("Successfully updated nutrientTerminology");
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Update Nutrient Terminology.");
  } finally {
    dispatch(updateLoading(false));
  }
};
