export const AmplifyAuthStatus = {
  signIn: "signIn",
  signUp: "signUp",
  confirmSignIn: "confirmSignIn",
  confirmSignUp: "confirmSignUp",
  forgotPassword: "forgotPassword",
  verifyContact: "verifyContact",
  signedIn: "signedIn",
  resetPassword: "resetPassword",
  requireNewPassword: "requireNewPassword",
  mfaRequired: "requireNewPassword"
};
