/* eslint-disable no-await-in-loop */
/* eslint-disable security/detect-object-injection */
import "./image.css";

import { AmplifyS3Image } from "@aws-amplify/ui-react";
import React, { FunctionComponent } from "react";
import { FieldArrayMethodProps } from "react-hook-form";
import { useDispatch } from "react-redux";

import awsConfig from "../../../aws-exports";
import {
  removeImageFromS3,
  swapImagePosition,
  uploadImageToS3
} from "../../../services";

interface ImageUploadProp {
  image_field: Record<"id", string>[];
  image_append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  image_swap: (indexA: number, indexB: number) => void;
  image_remove: (index?: number | number[] | undefined) => void;
  title?: string;
}
export const ImageUpload: FunctionComponent<ImageUploadProp> = ({
  image_field,
  image_append,
  image_swap,
  image_remove,
  title
}) => {
  const bucket = awsConfig.aws_user_files_s3_bucket;
  const region = awsConfig.aws_user_files_s3_bucket_region;
  const dispatch = useDispatch();

  return (
    <label className="text-sm" htmlFor="image">
      {title !== "" ? title : "Images"}
      <div id="image" className="flex flex-col w-full">
        <div className="flex flex-col justify-center py-4 mb-4 overflow-auto border-2 border-gray-400 border-dashed items-top ">
          <input
            className="px-4"
            type="file"
            multiple
            onChange={(event) => {
              uploadImageToS3({
                bucket,
                region,
                event,
                image_append,
                dispatch
              });
            }}
            accept="image/*"
          />
          <div className="flex flex-row p-12">
            {image_field.map((image, index) => (
              <div
                key={image.id}
                className="flex flex-col justify-center p-4 m-4 align-middle border w-60 h-60"
              >
                <AmplifyS3Image key={image.id} imgKey={image.id} />
                <button
                  onClick={() => {
                    removeImageFromS3({
                      key: image.id,
                      index,
                      dispatch,
                      image_remove
                    });
                  }}
                  type="button"
                >
                  Delete Image
                </button>
                <div>
                  {index > 0 && (
                    <div style={{ float: "left" }}>
                      <button
                        onClick={() => {
                          swapImagePosition({ index, pos: -1, image_swap });
                        }}
                        type="button"
                      >
                        Move Left
                      </button>
                    </div>
                  )}
                  {image_field.length - 1 > index && (
                    <div style={{ float: "right" }}>
                      <button
                        onClick={() => {
                          swapImagePosition({ index, pos: +1, image_swap });
                        }}
                        type="button"
                      >
                        Move Right
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </label>
  );
};

ImageUpload.defaultProps = {
  title: ""
};
