import { API } from "aws-amplify";
import { toast } from 'react-toastify';

import { updateContactForm } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { UpdateContactFormInput } from "../../../types/api";

interface updateContactFormDetailProp {
  contactFormData: UpdateContactFormInput;
  dispatch: AppDispatch;
}
export const updateContactFormDetail: ({
  contactFormData,
  dispatch
}: updateContactFormDetailProp) => Promise<void> = async ({
  contactFormData,
  dispatch
}: updateContactFormDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const updateResult = await API.graphql({
      query: updateContactForm,
      variables: { input: contactFormData }
    });
    if (updateResult) {
      toast.dark("Success update contactForm");
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Update ContactForm.");
  } finally {
    dispatch(updateLoading(false));
  }
};
