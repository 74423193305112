import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { Dispatch, DispatchWithoutAction, SetStateAction } from "react";
import { toast } from 'react-toastify';

import { deleteNutrientTerminology } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { DeleteNutrientTerminologyMutation, NutrientTerminology } from "../../../types/api";

interface deleteNutrientTerminologyByIdProps {
  id: string | undefined;
  index: number;
  dispatch: AppDispatch;
  updateNutrientTerminologys: Dispatch<SetStateAction<(NutrientTerminology | null)[]>>;
  forceUpdate: DispatchWithoutAction;
}
export const deleteNutrientTerminologyById: ({
  id,
  index,
  dispatch,
  updateNutrientTerminologys,
  forceUpdate
}: deleteNutrientTerminologyByIdProps) => Promise<void> = async ({
  id,
  index,
  dispatch,
  updateNutrientTerminologys,
  forceUpdate
}: deleteNutrientTerminologyByIdProps) => {
  try {
    dispatch(updateLoading(true));
    const deleteResponse: GraphQLResult<DeleteNutrientTerminologyMutation> =
      await API.graphql({
        query: deleteNutrientTerminology,
        variables: { input: { id } }
      }) as GraphQLResult<DeleteNutrientTerminologyMutation>;
    if (deleteResponse.data?.deleteNutrientTerminology) {
      updateNutrientTerminologys((prevState) => {
        if (prevState) {
          prevState.splice(index, 1);
        }
        return prevState;
      });
      forceUpdate();
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Delete Nutrient Terminology.");
  } finally {
    dispatch(updateLoading(false));
  }
};
