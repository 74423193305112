import React, { FunctionComponent } from "react";
import {
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister
} from "react-hook-form";

import {  TextInput } from "../../components/Form";
import { CreateContactFormInput } from "../../types/api";

interface ContactFormFormProp {
  onSubmit: (data: CreateContactFormInput) => void;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  created?: boolean;
}
export const ContactFormForm: FunctionComponent<ContactFormFormProp> = ({
  onSubmit,
  register,
  handleSubmit,
  created
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className="flex flex-row justify-center align-middle">
      <div className="w-full mb-6 md:w-3/5">
        <div className="text-right">
          <button
            className="px-4 py-2 my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
        <div className="mb-6">
          <TextInput label="Name" register={register("name")} disabled />
        </div>

        <div className="mb-6">
          <TextInput label="Email" register={register("email")} disabled />
        </div>

        <div className="mb-6">
          <TextInput label="Phone" register={register("phone")} disabled />
        </div>

        <div className="mb-6">
          <TextInput label="Message" register={register("message")} disabled />
        </div>

        <div className="mb-6">
          <TextInput label="Status" register={register("status")}/>
        </div>

        <div className="w-full text-center">
          <button
            className="px-4 py-2 mx-auto my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  </form>
);

ContactFormForm.defaultProps = {
  created: false
};
