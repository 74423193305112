import React, { FunctionComponent, ReactNode } from "react";
import { useSelector } from "react-redux";

import { Footer } from "../../components/Footer";
import { LeftSidebar } from "../../components/LeftSidebar";
import { Navbar } from "../../components/Navbar";
import { RootState } from "../../redux/store";

interface DefaultLayoutProp {
  children: ReactNode;
}
export const DefaultLayout: FunctionComponent<DefaultLayoutProp> = ({
  children
}) => {
  const title = useSelector((state: RootState) => state.app.title);
  return (
    <div className="flex h-screen bg-gray-200">
      <LeftSidebar />
      <div className="flex flex-col flex-1 overflow-hidden">
        <Navbar />
        <main className="flex flex-col flex-1 h-full overflow-x-hidden overflow-y-auto bg-green-50">
          <div className="flex flex-col flex-1 p-4 m-2 bg-white border-2 border-green-600 rounded-lg shadow-lg md:m-6">
            <h3 className="w-full text-3xl font-medium text-center text-gray-900">
              {title}
            </h3>
            <div className="flex-1 px-0 py-8 md:px-8">{children}</div>
          </div>
          <Footer />
        </main>
      </div>
    </div>
  );
};
