import React, { FunctionComponent } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface TextInputProp {
  label: string;
  type?: string;
  register: UseFormRegisterReturn;
  disabled?: boolean;
}
export const TextInput: FunctionComponent<TextInputProp> = ({
  label,
  type,
  register,
  disabled
}) => (
  <label className="text-sm" htmlFor={register.name}>
    {label}
    <input
      disabled={disabled}
      type={type}
      className="w-full p-2 mt-3 border border-gray-300 rounded outline-none focus:shadow focus:border-green-600"
      name={register.name}
      onChange={register.onChange}
      onBlur={register.onBlur}
      ref={register.ref}
    />
  </label>
);

TextInput.defaultProps = {
  type: "text",
  disabled: false
};
