import React, { FunctionComponent, ReactNode } from "react";

import { AuthMiddleware } from "./AuthMiddleware";
import { LoaderMiddleware } from "./LoaderMiddleware";

interface AppMiddlewareProp {
  children: ReactNode;
}
export const AppMiddleware: FunctionComponent<AppMiddlewareProp> = ({
  children
}) => (
  <LoaderMiddleware>
    <AuthMiddleware>{children}</AuthMiddleware>
  </LoaderMiddleware>
);
