import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  FieldArrayMethodProps,
  FieldValues,
  UseFormSetValue
} from "react-hook-form";
import { toast } from 'react-toastify';

import { getRack } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { GetRackQuery } from "../../../types/api";

interface getRackDetailProp {
  dispatch: AppDispatch;
  id: string | undefined;
  setValue: UseFormSetValue<FieldValues>;
  append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
}
export const getRackDetail: ({
  dispatch,
  id,
  setValue,
  append,
}: getRackDetailProp) => Promise<void> = async ({
  dispatch,
  id,
  setValue,
  append,
}: getRackDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const data: GraphQLResult<GetRackQuery> = await API.graphql({
      query: getRack,
      variables: { id }
    }) as GraphQLResult<GetRackQuery>;
    if (data.data?.getRack) {
      const rackData = data.data?.getRack;
      setValue('id', rackData.id)
      setValue('name', rackData.name)
      setValue('description', rackData.description)
      setValue('price', rackData.price)
      rackData.image_url?.forEach((image_url) => {
          append({id: image_url?.key, bucket:image_url?.bucket, region:image_url?.region, key:image_url?.key})
      })
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Get Rack by ID.");
  } finally {
    dispatch(updateLoading(false));
  }
};
