import React, { FunctionComponent, ReactNode } from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  updateLeftSidebarOpen,
  updateLoading
} from "../../redux/slice/appSlice";

interface NavLinkComponentProp {
  children: ReactNode | string;
  name: string;
  path: string;
}
export const NavLinkComponent: FunctionComponent<NavLinkComponentProp> = ({
  children,
  name,
  path
}) => {
  const dispatch = useDispatch();
  return (
    <NavLink
      to={path}
      className="flex items-center px-6 py-2 mt-1 text-gray-100 hover:bg-green-600 hover:bg-opacity-25 hover:text-gray-50"
      onClick={() => {
        dispatch(updateLoading(true));
        dispatch(updateLeftSidebarOpen(false));
      }}
    >
      {children}
      <span className="mx-3">{name}</span>
    </NavLink>
  );
};
