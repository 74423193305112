import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';

import { updatePest } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  UpdatePestInput,
  UpdatePestMutation
} from "../../../types/api";

interface updatePestDetailProp {
  dispatch: AppDispatch;
  pestData: UpdatePestInput;
}
export const updatePestDetail: ({
  dispatch,
  pestData
}: updatePestDetailProp) => Promise<void> = async ({
  dispatch,
  pestData
}: updatePestDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const updateResponse: GraphQLResult<UpdatePestMutation> =
      await API.graphql({
        query: updatePest,
        variables: { input: pestData }
      }) as GraphQLResult<UpdatePestMutation>;
    if (updateResponse && updateResponse.data?.updatePest) {
      toast.dark("Successfully updated pest");
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Update Pest.");
  } finally {
    dispatch(updateLoading(false));
  }
};
