import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';

import { updateLoading } from "../../../redux/slice/appSlice";
import { updateLoggedIn } from "../../../redux/slice/authSlice";
import { AppDispatch } from "../../../redux/store";

interface checkUserProp {
  dispatch: AppDispatch;
}
export const checkUser:({ dispatch }: checkUserProp) => Promise<void> = async ({ dispatch }: checkUserProp) => {
  dispatch(updateLoading(true));
  try {
    const userResult = await Auth.currentAuthenticatedUser();
    
    if (userResult &&  userResult.signInUserSession.accessToken.payload["cognito:groups"] && userResult.signInUserSession.accessToken.payload["cognito:groups"].includes('admin')) {
      dispatch(updateLoggedIn(true));
    } else {
      toast.dark("Error: User doesn't have permission.")
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Checking user Auth.");
  } finally {
    dispatch(updateLoading(false));
  }
};
