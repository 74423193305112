import { AiOutlineLoading3Quarters } from "@react-icons/all-files/ai/AiOutlineLoading3Quarters";
import React, { FunctionComponent } from "react";

export const LoaderComponent: FunctionComponent = () => (
  <div className="fixed top-0 left-0 z-50 flex flex-col justify-center w-full h-full overflow-hidden align-middle bg-black bg-opacity-70">
    <AiOutlineLoading3Quarters
      size="50"
      color="white"
      className="mx-auto animate-spin"
    />
  </div>
);
