import React, { FunctionComponent, useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateLoading } from "../../redux/slice/appSlice";
import { createNutrientTerminologyDetail } from "../../services";
import { CreateNutrientTerminologyInput } from "../../types/api";
import { NutrientTerminologyForm } from "./form";

export const NutrientTerminologyCreate: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate;
  const { register, handleSubmit, control } = useForm();
  const { fields, append, swap, remove } = useFieldArray({
    name: "image_url",
    control
  });
  useEffect(() => {
    dispatch(updateLoading(false));
  }, [dispatch]);

  const onSubmit = async (data: FieldValues) => {
    const nutrientTerminologyData: CreateNutrientTerminologyInput = {
      name: data.name,
      meaning: data.meaning,
      image_url: data.image_url
    };
    createNutrientTerminologyDetail({
      nutrientTerminologyData,
      dispatch,
      navigate
    });
  };
  return (
    <NutrientTerminologyForm
      onSubmit={onSubmit}
      register={register}
      handleSubmit={handleSubmit}
      image_field={fields}
      image_append={append}
      image_swap={swap}
      image_remove={remove}
    />
  );
};
