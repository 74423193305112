import React, { FunctionComponent, useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getPlantDetail, updatePlantDetail } from "../../services";
import { UpdatePlantInput } from "../../types/api";
import { PlantForm } from "./form";

export const PlantDetailWithID: FunctionComponent = () => {
  const dispatch = useDispatch();

  const { register, handleSubmit, control, setValue } = useForm();
  const { fields, append, swap, remove } = useFieldArray({
    name: "image_url",
    control
  });
  const { id } = useParams();
  useEffect(() => {
    getPlantDetail({ dispatch, id, setValue, append });
  }, [id, append, setValue, dispatch]);

  const onSubmit = async (data: FieldValues) => {
    const plantData: UpdatePlantInput = {
      id: data.id,
      name: data.name,
      type: data.type,
      scientific_name: data.scientific_name,
      name_bm: data.name_bm,
      name_cn: data.name_cn,
      temperature: {
        min: data.temperature_min,
        max: data.temperature_max,
        scale_unit: data.temperature_scale_unit
      },
      height: {
        min: data.height_min,
        max: data.height_max,
        scale_unit: data.height_scale_unit
      },
      seed_planting_depth: {
        min: data.seed_planting_depth_min,
        max: data.seed_planting_depth_max,
        scale_unit: data.seed_planting_depth_scale_unit
      },
      planting_distance_between_plants: {
        min: data.planting_distance_between_plants_min,
        max: data.planting_distance_between_plants_max,
        scale_unit: data.planting_distance_between_plants_scale_unit
      },
      pruning_requirement: data.pruning_requirement,
      soil_moisture: data.soil_moisture,
      sunlight: {
        min: data.sunlight_min,
        max: data.sunlight_max,
        scale_unit: data.sunlight_scale_unit
      },
      germination: {
        min: data.germination_min,
        max: data.germination_max,
        scale_unit: data.germination_scale_unit
      },
      harvest_day: {
        min: data.harvest_day_min,
        max: data.harvest_day_max,
        scale_unit: data.harvest_day_scale_unit
      },
      harvest_month: {
        min: data.harvest_month_min,
        max: data.harvest_month_max,
        scale_unit: data.harvest_month_scale_unit
      },
      water_amount: data.water_amount,
      watering_frequency: data.watering_frequency,
      watering_duration: data.watering_duration,
      plant_nature: data.plant_nature,
      pest_info: data.pest_info,
      disease_info: data.disease_info,
      image_url: data.image_url
    };
    updatePlantDetail({ dispatch, plantData });
  };

  return (
    <PlantForm
      onSubmit={onSubmit}
      register={register}
      handleSubmit={handleSubmit}
      image_field={fields}
      image_append={append}
      image_swap={swap}
      image_remove={remove}
      created
    />
  );
};
