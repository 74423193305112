import { Auth } from "aws-amplify";
import React, { FunctionComponent, useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';

import { BaseButton } from "../components/Buttons";
import { updateLoading } from "../redux/slice/appSlice";
import { updateLoggedIn } from "../redux/slice/authSlice";
import { AppDispatch } from "../redux/store";

export const Dashboard: FunctionComponent = () => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const modules = {
    toolbar: [
      // [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike",
      //  "blockquote"
      ],
      // [
      //   { list: "ordered" },
      //   { list: "bullet" },
      //   { indent: "-1" },
      //   { indent: "+1" }
      // ],
      // ["link", "image"],
      // ["clean"]
    ]
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image"
  ];

  return (
    <div className="w-full text-center">
      <div className="py-4 text-3xl font-bold">Dashboard</div>
      <div className="w-40 mx-auto">
        <BaseButton
          label="Sign Out"
          onClick={async () => {
            dispatch(updateLoading(true));
            try {
              await Auth.signOut();
              dispatch(updateLoggedIn(false));
            } catch (err) {
              toast.dark(`Error: ${err}.`)
            }
            dispatch(updateLoading(false));
          }}
        />
      </div>
      <div>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          modules={modules}
          formats={formats}
        />
      </div>
      <div className="m-4">
        <div className="text-2xl font-bold">Test Result</div>
        <div className="p-4 m-2 border-2 border-gray-900">{ReactHtmlParser(value)}</div>
      </div>
      <button
        type="button"
        onClick={() => {
          toast.dark(value)
        }}
      >
        test
      </button>
    </div>
  );
};
