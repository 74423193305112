import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { Dispatch, SetStateAction } from "react";
import { toast } from 'react-toastify';

import { listDiseases } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { Disease, ListDiseasesQuery } from "../../../types/api";

interface getDieseaseListProp {
  dispatch: AppDispatch;
  updateDiseases: Dispatch<SetStateAction<(Disease | null)[]>>;
}

export const getDiseaseList: ({
  dispatch,
  updateDiseases
}: getDieseaseListProp) => Promise<void> = async ({
  dispatch,
  updateDiseases
}: getDieseaseListProp) => {
  try {
    const diseaseListResponse: GraphQLResult<ListDiseasesQuery> =
      await API.graphql({
        query: listDiseases
      }) as GraphQLResult<ListDiseasesQuery>;
    if (diseaseListResponse.data?.listDiseases?.items) {
      updateDiseases(diseaseListResponse.data.listDiseases.items);
    }
  } catch (err) {
    // console.log(err);
    toast.dark("Error: Get Disease List");
  } finally {
    dispatch(updateLoading(false));
  }
};
