import React, { FunctionComponent, ReactNode } from "react";
import { useSelector } from "react-redux";

import { LoaderComponent } from "../../components/Loader";
import { RootState } from "../../redux/store";

interface LoaderMiddlewareProp {
  children: ReactNode;
}
export const LoaderMiddleware: FunctionComponent<LoaderMiddlewareProp> = ({
  children
}) => {
  const loading = useSelector((state: RootState) => state.app.loading);
  return (
    <>
      {loading && <LoaderComponent />}
      {children}
    </>
  );
};
