import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {  FieldArrayMethodProps,FieldValues, UseFormSetValue } from "react-hook-form";
import { toast } from 'react-toastify';

import { getDisease } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { GetDiseaseQuery } from "../../../types/api";

interface getDiseaseDetailProp {
  dispatch: AppDispatch;
  id: string;
  setValue: UseFormSetValue<FieldValues>;
  append: (value: Partial<unknown> | Partial<unknown>[], options?: FieldArrayMethodProps | undefined) => void;
}

export const getDiseaseDetail: ({
  dispatch,
  id,
  setValue,
  append
}: getDiseaseDetailProp) => Promise<void> = async ({
  dispatch,
  id,
  setValue,
  append
}: getDiseaseDetailProp) => {
  try {
    const diseaseResult: GraphQLResult<GetDiseaseQuery> = await API.graphql({
      query: getDisease,
      variables: { id }
    }) as GraphQLResult<GetDiseaseQuery>;
    if (diseaseResult.data?.getDisease) {
        const diseaseData = diseaseResult.data.getDisease;
        setValue("id", diseaseData.id);
        setValue("name", diseaseData.name);
        setValue("description", diseaseData.description);
        setValue("detection", diseaseData.detection);
        setValue("management", diseaseData.management);
        setValue("notes", diseaseData.notes);
        diseaseData.image_url?.forEach((image_url) => {
          append({
            id: image_url?.key,
            bucket: image_url?.bucket,
            region: image_url?.region,
            key: image_url?.key
          });
        });

    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Get Disease by ID.");
  } finally {
    dispatch(updateLoading(false));
  }
};
