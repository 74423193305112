import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { Dispatch,SetStateAction } from "react";
import { toast } from 'react-toastify';

import { listNutrientTerminologys } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { ListNutrientTerminologysQuery, NutrientTerminology } from "../../../types/api";

interface getNutrientTerminologyDetailListProp {
  dispatch: AppDispatch;
  updateNutrientTerminologys: Dispatch<SetStateAction<(NutrientTerminology|null)[]>>;
}
export const getNutrientTerminologyDetailList: ({
  dispatch,
  updateNutrientTerminologys
}: getNutrientTerminologyDetailListProp) => Promise<void> = async ({
  dispatch,
  updateNutrientTerminologys
}: getNutrientTerminologyDetailListProp) => {
  try {
    dispatch(updateLoading(true));
    const getResponse: GraphQLResult<ListNutrientTerminologysQuery> = await API.graphql({
      query: listNutrientTerminologys
    }) as GraphQLResult<ListNutrientTerminologysQuery>;
    if (getResponse.data?.listNutrientTerminologys?.items) {
        updateNutrientTerminologys(getResponse.data.listNutrientTerminologys.items);
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Get Nutrient Terminology list.");
  } finally {
    dispatch(updateLoading(false));
  }
};
