import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';

import { updatePlant } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  UpdatePlantInput,
  UpdatePlantMutation
} from "../../../types/api";

interface updatePlantDetailProp {
  dispatch: AppDispatch;
  plantData: UpdatePlantInput;
}
export const updatePlantDetail: ({
  dispatch,
  plantData
}: updatePlantDetailProp) => Promise<void> = async ({
  dispatch,
  plantData
}: updatePlantDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const updateResponse: GraphQLResult<UpdatePlantMutation> =
      await API.graphql({
        query: updatePlant,
        variables: { input: plantData }
      }) as GraphQLResult<UpdatePlantMutation>;
    if (updateResponse && updateResponse.data?.updatePlant) {
      toast.dark("Successfully updated plant");
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Update Plant.");
  } finally {
    dispatch(updateLoading(false));
  }
};
