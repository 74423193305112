import React, {
  FunctionComponent,
  useEffect,
  useReducer,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TableComponent } from "../../components/Table";
import { deletePlantById, getPlantDetailList } from "../../services";
import { Plant } from "../../types/api";

export const PlantDashboard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [plants, updatePlants] = useState<Array<Plant|null>>([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    getPlantDetailList({ updatePlants, dispatch });
  }, [dispatch]);

  const navigate = useNavigate();
  const colDisplay = ["name", "type", "image_url"];
  const editFunc = (id: string | undefined) => {
    navigate(`/plant/${id}`);
  };
  const deleteFunc = async (id: string | undefined, index: number) => {
    deletePlantById({ id, index, forceUpdate, updatePlants, dispatch });
  };
  const createFunc = () => {
    navigate("/plant/create");
  };

  return (
    <TableComponent
      dataInput={plants}
      colDisplay={colDisplay}
      createFunc={createFunc}
      editFunc={editFunc}
      deleteFunc={deleteFunc}
    />
  );
};
