import React, { FunctionComponent, ReactNode } from "react";

interface AuthLayoutProp {
  children: ReactNode;
}
export const AuthLayout: FunctionComponent<AuthLayoutProp> = ({ children }) => (
  <div className="min-h-screen bg-green-100">
    <div className="flex flex-col items-center">
      <div className="w-full lg:w-1/3 md:w-1/2 mt-14 ">{children}</div>
    </div>
  </div>
);

export default AuthLayout;
