import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { updateAuthStatus } from "../../redux/slice/authSlice";
import { AppDispatch } from "../../redux/store";
import { loginService } from "../../services";
import { AmplifyAuthStatus } from "../../types/AmplifyAuthStatus";
import { BaseButton, TextButton } from "../Buttons";
import { TextInput } from "../Form";

type FormValues = {
  username: string,
  password: string
};

export const LoginForm: FunctionComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data: FormValues) => {
    loginService({
      dispatch,
      username: data.username,
      password: data.password
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="text-3xl font-black">Sign in to your account</p>
      <div className="mt-10">
        <TextInput label="Username" register={register("username")} />
      </div>
      <div className="mt-4">
        <TextInput
          label="Password"
          register={register("password")}
          type="password"
        />
      </div>
      <div className="mt-4">
        <TextButton
          label="Forgot your password?"
          onClick={() => {
            dispatch(updateAuthStatus(AmplifyAuthStatus.forgotPassword));
          }}
          isRight
        />
      </div>
      <div className="mt-4">
        <BaseButton submit label="Continue" />
      </div>
    </form>
  );
};
