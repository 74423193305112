import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  FieldArrayMethodProps,
  FieldValues,
  UseFormSetValue
} from "react-hook-form";
import { toast } from 'react-toastify';

import { getPest } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { GetPestQuery } from "../../../types/api";

interface getPestDetailProp {
  dispatch: AppDispatch;
  id: string | undefined;
  setValue: UseFormSetValue<FieldValues>;
  append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
}
export const getPestDetail: ({
  dispatch,
  id,
  setValue,
  append,
}: getPestDetailProp) => Promise<void> = async ({
  dispatch,
  id,
  setValue,
  append,
}: getPestDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const data: GraphQLResult<GetPestQuery> = await API.graphql({
      query: getPest,
      variables: { id }
    }) as GraphQLResult<GetPestQuery>;
    if (data.data?.getPest) {
      const pestData = data.data?.getPest;
      setValue('id', pestData.id)
      setValue('name', pestData.name)
      setValue('description', pestData.description)
      setValue('detection', pestData.detection)
      setValue('management', pestData.management)
      setValue('notes', pestData.notes)
      pestData.image_url?.forEach((image_url) => {
          append({id: image_url?.key, bucket:image_url?.bucket, region:image_url?.region, key:image_url?.key})
      })
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Get Pest by ID.");
  } finally {
    dispatch(updateLoading(false));
  }
};
