import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { FieldValues, UseFormSetValue } from "react-hook-form";
import { toast } from 'react-toastify';

import { getContactForm } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { GetContactFormQuery } from "../../../types/api";

interface getContactFormDetailProp {
  dispatch: AppDispatch;
  id: string | undefined;
  setValue: UseFormSetValue<FieldValues>;
}

export const getContactFormDetail: ({
  dispatch,
  id,
  setValue
}: getContactFormDetailProp) => Promise<void> = async ({
  dispatch,
  id,
  setValue
}: getContactFormDetailProp) => {
  try {
    const contactFormResult: GraphQLResult<GetContactFormQuery> = await API.graphql({
      query: getContactForm,
      variables: { id }
    }) as GraphQLResult<GetContactFormQuery>;
    if (contactFormResult.data?.getContactForm) {
      
        const contactFormData = contactFormResult.data.getContactForm;
        setValue("id", contactFormData.id);
        setValue("name", contactFormData.name);
        setValue("email", contactFormData.email);
        setValue("phone", contactFormData.phone);
        setValue("message", contactFormData.message);
        setValue("status", contactFormData.status === null || contactFormData.status === '' ? "Pending": contactFormData.status);
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Get ContactForm by ID.");
  } finally {
    dispatch(updateLoading(false));
  }
};
