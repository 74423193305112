import React, { FunctionComponent } from "react";
import {
  FieldArrayMethodProps,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister
} from "react-hook-form";

import { ImageUpload, TextInput } from "../../components/Form";
import { CreateDiseaseInput } from "../../types/api";

interface DiseaseFormProp {
  onSubmit: (data: CreateDiseaseInput) => void;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  image_field: Record<"id", string>[];
  image_append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  image_swap: (indexA: number, indexB: number) => void;
  image_remove: (index?: number | number[] | undefined) => void;
  created?: boolean;
}
export const DiseaseForm: FunctionComponent<DiseaseFormProp> = ({
  onSubmit,
  register,
  handleSubmit,
  image_field,
  image_append,
  image_swap,
  image_remove,
  created
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className="flex flex-row justify-center align-middle">
      <div className="w-full mb-6 md:w-3/5">
        <div className="text-right">
          <button
            className="px-4 py-2 my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
        <div className="mb-6">
          <TextInput label="Name" register={register("name")} />
        </div>

        <div className="mb-6">
          <TextInput label="Description" register={register("description")} />
        </div>

        <div className="mb-6">
          <TextInput label="Detection" register={register("detection")} />
        </div>

        <div className="mb-6">
          <TextInput label="Management" register={register("management")} />
        </div>

        <div className="mb-6">
          <TextInput label="Notes" register={register("notes")} />
        </div>

        <ImageUpload
          image_field={image_field}
          image_append={image_append}
          image_swap={image_swap}
          image_remove={image_remove}
        />
        <div className="w-full text-center">
          <button
            className="px-4 py-2 mx-auto my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  </form>
);

DiseaseForm.defaultProps = {
  created: false
};
