import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { NavigateFunction } from "react-router-dom";
import { toast } from 'react-toastify';

import { createPest } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  CreatePestInput,
  CreatePestMutation
} from "../../../types/api";

interface createPestDetailProp {
  pestData: CreatePestInput;
  dispatch: AppDispatch;
  navigate: NavigateFunction;
}

export const createPestDetail: ({
  pestData,
  dispatch,
  navigate
}: createPestDetailProp) => Promise<void> = async ({
  pestData,
  dispatch,
  navigate
}: createPestDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const createResponse: GraphQLResult<CreatePestMutation> =
      await API.graphql({
        query: createPest,
        variables: { input: pestData }
      }) as GraphQLResult<CreatePestMutation>;
    if (createResponse && createResponse.data?.createPest) {
      navigate(`/pest/${createResponse.data.createPest.id}`);
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Create Pest.");
  } finally {
    dispatch(updateLoading(false));
  }
};
