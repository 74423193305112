import React, { FunctionComponent, useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateLoading } from "../../redux/slice/appSlice";
import { createPestDetail } from "../../services";
import { CreatePestInput } from "../../types/api";
import { PestForm } from "./form";

export const PestCreate: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, control, watch, setValue } = useForm();
  const { fields, append, swap, remove } = useFieldArray({
    name: "image_url",
    control
  });
  useEffect(() => {
    dispatch(updateLoading(false));
  }, [dispatch]);
  const onSubmit = async (data: FieldValues) => {
    const pestData: CreatePestInput = {
      name: data.name,
      description: data.description,
      detection: data.detection,
      management: data.management,
      notes: data.notes,
      image_url: data.image_url
    };
    createPestDetail({ dispatch, pestData, navigate });
  };
  return (
    <PestForm
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      image_field={fields}
      image_append={append}
      image_swap={swap}
      image_remove={remove}
      setValue={setValue}
      watch={watch}
    />
  );
};
