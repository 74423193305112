import { ICredentials } from "@aws-amplify/core";
import React, { FunctionComponent, ReactElement } from "react";

interface BaseButtonProp {
  label: string | ReactElement;
  submit?: boolean;
  color?: string;
  onClick?: () => Promise<ICredentials> | void | undefined | Promise<void>;
}

export const BaseButton: FunctionComponent<BaseButtonProp> = ({
  label,
  submit,
  color,
  onClick
}) => {
  if (submit) {
    return (
      <button
        className={`w-full p-3 mt-6 text-white border border-gray-300 rounded-full active:bg-green-800 hover:bg-green-400 ${color}`}
        type="submit"
      >
        {label}
      </button>
    );
  }
  return (
    <button
      className={`w-full p-3 mt-6 text-white border border-gray-300 rounded-full active:bg-green-800 hover:bg-green-400 ${color}`}
      type="button"
      onClick={onClick}
    >
      {label}
    </button>
  );
};

BaseButton.defaultProps = {
  onClick: undefined,
  color: "bg-green-600",
  submit: false
};
