import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { NavigateFunction } from "react-router-dom";
import { toast } from 'react-toastify';

import { createNutrientTerminology } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  CreateNutrientTerminologyInput,
  CreateNutrientTerminologyMutation
} from "../../../types/api";

interface createNutrientTerminologyDetailProp {
  nutrientTerminologyData: CreateNutrientTerminologyInput;
  dispatch: AppDispatch;
  navigate: NavigateFunction;
}

export const createNutrientTerminologyDetail: ({
  nutrientTerminologyData,
  dispatch,
  navigate
}: createNutrientTerminologyDetailProp) => Promise<void> = async ({
  nutrientTerminologyData,
  dispatch,
  navigate
}: createNutrientTerminologyDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const createResponse: GraphQLResult<CreateNutrientTerminologyMutation> =
      await API.graphql({
        query: createNutrientTerminology,
        variables: { input: nutrientTerminologyData }
      }) as GraphQLResult<CreateNutrientTerminologyMutation>;
    if (createResponse && createResponse.data?.createNutrientTerminology) {
      navigate(`/nutrient-terminology/${createResponse.data.createNutrientTerminology.id}`);
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Create Nutrient Terminology.");
  } finally {
    dispatch(updateLoading(false));
  }
};
