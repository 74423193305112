import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { NavigateFunction } from "react-router-dom";
import { toast } from 'react-toastify';

import { createNutrient } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  CreateNutrientInput,
  CreateNutrientMutation
} from "../../../types/api";

interface createNutrientDetailProp {
  nutrientData: CreateNutrientInput;
  dispatch: AppDispatch;
  navigate: NavigateFunction;
}

export const createNutrientDetail: ({
  nutrientData,
  dispatch,
  navigate
}: createNutrientDetailProp) => Promise<void> = async ({
  nutrientData,
  dispatch,
  navigate
}: createNutrientDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const createResponse: GraphQLResult<CreateNutrientMutation> =
      await API.graphql({
        query: createNutrient,
        variables: { input: nutrientData }
      }) as GraphQLResult<CreateNutrientMutation>;
    if (createResponse && createResponse.data?.createNutrient) {
      navigate(`/nutrient/${createResponse.data.createNutrient.id}`);
    }
  } catch (err) {
    // console.log("err")
    toast.dark("Error: Create Nutrient.");
  } finally {
    dispatch(updateLoading(false));
  }
};
