import { API } from "aws-amplify";
import { toast } from 'react-toastify';

import { updateDisease } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { UpdateDiseaseInput } from "../../../types/api";

interface updateDiseaseDetailProp {
  diseaseData: UpdateDiseaseInput;
  dispatch: AppDispatch;
}
export const updateDiseaseDetail: ({
  diseaseData,
  dispatch
}: updateDiseaseDetailProp) => Promise<void> = async ({
  diseaseData,
  dispatch
}: updateDiseaseDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const updateResult = await API.graphql({
      query: updateDisease,
      variables: { input: diseaseData }
    });
    if (updateResult) {
      toast.dark("Success update disease");
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Update Disease.");
  } finally {
    dispatch(updateLoading(false));
  }
};
