import React, { FunctionComponent, useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getNutrientDetail, updateNutrientDetail } from "../../services";
import { UpdateNutrientInput } from "../../types/api";
import { NutrientForm } from "./form";

export const NutrientDetailWithID: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, control, setValue } = useForm();
  const {
    fields: deficient_fields,
    append: deficient_append,
    swap: deficient_swap,
    remove: deficient_remove
  } = useFieldArray({
    name: "deficient_image",
    control
  });
  const {
    fields: toxic_fields,
    append: toxic_append,
    swap: toxic_swap,
    remove: toxic_remove
  } = useFieldArray({
    name: "toxic_image",
    control
  });
  const { id } = useParams();
  useEffect(() => {
    getNutrientDetail({
      dispatch,
      id,
      setValue,
      deficient_append,
      toxic_append
    });
  }, [id, deficient_append, toxic_append, setValue, dispatch]);

  const onSubmit = async (data: FieldValues) => {
    const nutrientData: UpdateNutrientInput = {
      id: data.id,
      name: data.name,
      short_name: data.short_name,
      type: data.type,
      importance: data.importance,
      deficient_symptom: data.deficient_symptom,
      leaf_position: data.leaf_position,
      deficient_image: data.deficient_image,
      worsen: data.worsen,
      solution: data.solution,
      toxic_symptom: data.toxic_symptom,
      toxic_image: data.toxic_image
    };
    updateNutrientDetail({ dispatch, nutrientData });
  };

  return (
    <NutrientForm
      onSubmit={onSubmit}
      register={register}
      handleSubmit={handleSubmit}
      deficient_fields={deficient_fields}
      deficient_append={deficient_append}
      deficient_swap={deficient_swap}
      deficient_remove={deficient_remove}
      toxic_fields={toxic_fields}
      toxic_append={toxic_append}
      toxic_swap={toxic_swap}
      toxic_remove={toxic_remove}
      created
    />
  );
};
