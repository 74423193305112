import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { NavigateFunction } from "react-router-dom";
import { toast } from 'react-toastify';

import { createPlant } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  CreatePlantInput,
  CreatePlantMutation
} from "../../../types/api";

interface createPlantDetailProp {
  plantData: CreatePlantInput;
  dispatch: AppDispatch;
  navigate: NavigateFunction;
}

export const createPlantDetail: ({
  plantData,
  dispatch,
  navigate
}: createPlantDetailProp) => Promise<void> = async ({
  plantData,
  dispatch,
  navigate
}: createPlantDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const createResponse: GraphQLResult<CreatePlantMutation> =
      await API.graphql({
        query: createPlant,
        variables: { input: plantData }
      }) as GraphQLResult<CreatePlantMutation>;
    if (createResponse && createResponse.data?.createPlant) {
      navigate(`/plant/${createResponse.data.createPlant.id}`);
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Create Plant.");
  } finally {
    dispatch(updateLoading(false));
  }
};
