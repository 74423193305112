import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

import { BaseButton } from "../components/Buttons";

export const Error404: FunctionComponent = () => {
  const navigate = useNavigate();

  const goToPreviousPath = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col justify-center flex-1 w-full align-center">
      <div className="m-12 text-3xl text-center text-bold">Error</div>
      <div className="mb-12 text-3xl text-center text-bold">
        Something went wrong, please click the bottom button to go back previous
        page.
      </div>
      <div className="w-40 mx-auto">
        <BaseButton label="Go Back" onClick={goToPreviousPath} />
      </div>
    </div>
  );
};
