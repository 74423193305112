import React, {
  FunctionComponent,
  useEffect,
  useReducer,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TableComponent } from "../../components/Table";
import {
  deleteNutrientTerminologyById,
  getNutrientTerminologyDetailList
} from "../../services";
import { NutrientTerminology } from "../../types/api";

export const NutrientTerminologyDashboard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [nutrientTerminologys, updateNutrientTerminologys] = useState<Array<NutrientTerminology|null>>([]);
  const navigate = useNavigate();
  const colDisplay = ["name", "type", "image_url"];

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    getNutrientTerminologyDetailList({ dispatch, updateNutrientTerminologys });
  }, [dispatch]);
  const editFunc = (id: string | undefined) => {
    navigate(`/nutrient-terminology/${id}`);
  };
  const deleteFunc = async (id: string | undefined, index: number) => {
    deleteNutrientTerminologyById({
      id,
      index,
      dispatch,
      updateNutrientTerminologys,
      forceUpdate
    });
  };
  const createFunc = () => {
    navigate("/nutrient-terminology/create");
  };

  return (
    <TableComponent
      dataInput={nutrientTerminologys}
      colDisplay={colDisplay}
      createFunc={createFunc}
      editFunc={editFunc}
      deleteFunc={deleteFunc}
    />
  );
};
