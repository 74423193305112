import React, { FunctionComponent, useEffect } from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getDiseaseDetail, updateDiseaseDetail } from "../../services";
import { UpdateDiseaseInput } from "../../types/api";
import { DiseaseForm } from "./form";

export const DiseaseDetailWithID: FunctionComponent = () => {
  const { register, handleSubmit, control, setValue } = useForm();
  const { fields, append, swap, remove } = useFieldArray({
    name: "image_url",
    control
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      getDiseaseDetail({ dispatch, id, setValue, append });
    }
  }, [id, append, setValue, dispatch]);

  const onSubmit = async (data: FieldValues) => {
    const diseaseData: UpdateDiseaseInput = {
      id: data.id,
      name: data.name,
      description: data.description,
      detection: data.detection,
      management: data.management,
      notes: data.notes,
      image_url: data.image_url
    };
    updateDiseaseDetail({ dispatch, diseaseData });
  };

  return (
    <DiseaseForm
      onSubmit={onSubmit}
      register={register}
      handleSubmit={handleSubmit}
      image_field={fields}
      image_append={append}
      image_swap={swap}
      image_remove={remove}
      created
    />
  );
};
