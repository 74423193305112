import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { Dispatch, SetStateAction } from "react";
import { toast } from 'react-toastify';

import { listContactForms } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { ContactForm, ListContactFormsQuery } from "../../../types/api";

interface getContactFormListProp {
  dispatch: AppDispatch;
  updateContactForms: Dispatch<SetStateAction<(ContactForm | null)[]>>;
}

export const getContactFormList: ({
  dispatch,
  updateContactForms
}: getContactFormListProp) => Promise<void> = async ({
  dispatch,
  updateContactForms
}: getContactFormListProp) => {
  try {
    const contactFormListResponse: GraphQLResult<ListContactFormsQuery> =
      await API.graphql({
        query: listContactForms
      }) as GraphQLResult<ListContactFormsQuery>;
    if (contactFormListResponse.data?.listContactForms?.items) {
      updateContactForms(contactFormListResponse.data.listContactForms.items);
    }
  } catch (err) {
    // console.log(err);
    toast.dark("Error: Get ContactForm List");
  } finally {
    dispatch(updateLoading(false));
  }
};
