import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { AuthLayout } from "../../layout";
import { RootState } from "../../redux/store";
import { AmplifyAuthStatus } from "../../types/AmplifyAuthStatus";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";
import { RequireNewPasswordForm } from "./RequireNewPasswordForm";
import { ResetPasswordForm } from "./ResetPasswordForm";

export const AuthComponent: FunctionComponent = () => {
  const authStatus = useSelector((state: RootState) => state.auth.authStatus);
  return (
    <AuthLayout>
      <div className="mb-8">
        <p className="pl-4 text-2xl font-semibold tracking-tight">
          Eco-Orion Admin
        </p>
      </div>
      <div className="px-16 bg-white rounded py-14 shadow-form">
        {authStatus === AmplifyAuthStatus.signIn && <LoginForm />}
        {authStatus === AmplifyAuthStatus.signUp && <RegisterForm />}
        {authStatus === AmplifyAuthStatus.requireNewPassword && (
          <RequireNewPasswordForm />
        )}
        {authStatus === AmplifyAuthStatus.forgotPassword && (
          <ForgotPasswordForm />
        )}
        {authStatus === AmplifyAuthStatus.resetPassword && (
          <ResetPasswordForm />
        )}
      </div>
    </AuthLayout>
  );
};
