import React, { FunctionComponent } from "react";
import {
  FieldArrayMethodProps,
  FieldValues,
  UseFormHandleSubmit,
  UseFormRegister
} from "react-hook-form";

import { ImageUpload, TextInput } from "../../components/Form";
import { CreateNutrientInput } from "../../types/api";

interface NutrientFormProp {
  onSubmit: (data: CreateNutrientInput) => void;
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  deficient_fields: Record<"id", string>[];
  deficient_append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  deficient_swap: (indexA: number, indexB: number) => void;
  deficient_remove: (index?: number | number[] | undefined) => void;
  toxic_fields: Record<"id", string>[];
  toxic_append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  toxic_swap: (indexA: number, indexB: number) => void;
  toxic_remove: (index?: number | number[] | undefined) => void;
  created?: boolean;
}
export const NutrientForm: FunctionComponent<NutrientFormProp> = ({
  onSubmit,
  register,
  handleSubmit,
  deficient_fields,
  deficient_append,
  deficient_swap,
  deficient_remove,
  toxic_fields,
  toxic_append,
  toxic_swap,
  toxic_remove,
  created
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <div className="flex flex-row justify-center align-middle">
      <div className="w-full mb-6 md:w-3/5">
        <div className="text-right">
          <button
            className="px-4 py-2 my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
        <div className="mb-6">
          <TextInput label="Name" register={register("name")} />
        </div>

        <div className="mb-6">
          <TextInput label="Short Name" register={register("short_name")} />
        </div>

        <div className="mb-6">
          <TextInput label="Type" register={register("type")} />
        </div>

        <div className="mb-6">
          <TextInput label="Importance" register={register("importance")} />
        </div>

        <div className="mb-6">
          <TextInput
            label="Leaf Position"
            register={register("leaf_position")}
          />
        </div>

        <div className="mb-6">
          <TextInput
            label="Deficient Symptom"
            register={register("deficient_symptom")}
          />
        </div>

        <ImageUpload
          title="Deficient Symptom Images"
          image_field={deficient_fields}
          image_append={deficient_append}
          image_swap={deficient_swap}
          image_remove={deficient_remove}
        />

        <div className="mb-6">
          <TextInput label="Worsen Condition" register={register("worsen")} />
        </div>

        <div className="mb-6">
          <TextInput label="Solution" register={register("solution")} />
        </div>

        <div className="mb-6">
          <TextInput
            label="Toxic Symptom"
            register={register("toxic_symptom")}
          />
        </div>

        <ImageUpload
          title="Toxic Symptom Example"
          image_field={toxic_fields}
          image_append={toxic_append}
          image_swap={toxic_swap}
          image_remove={toxic_remove}
        />
        <div className="w-full text-center">
          <button
            className="px-4 py-2 mx-auto my-2 font-bold text-white uppercase transition-all bg-purple-500 border-2 border-transparent rounded hover:border-purple-500 hover:bg-transparent hover:text-purple-500"
            type="submit"
          >
            {created ? "Update" : "Create"}
          </button>
        </div>
      </div>
    </div>
  </form>
);

NutrientForm.defaultProps = {
  created: false
};
