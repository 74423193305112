import { IconType } from "@react-icons/all-files";
import { AiOutlineBug } from "@react-icons/all-files/ai/AiOutlineBug";
import { GiBlackBook } from "@react-icons/all-files/gi/GiBlackBook";
import { GiMedicines } from "@react-icons/all-files/gi/GiMedicines";
import { GiTable } from "@react-icons/all-files/gi/GiTable";
import { RiPlantLine } from "@react-icons/all-files/ri/RiPlantLine";
import { FaDisease } from "react-icons/fa";

interface navLinksComponent {
  name: string;
  path: string;
  Icon: IconType;
}
export const contentManagementLinks: navLinksComponent[] = [
  {
    name: "Plant",
    path: "/plant",
    Icon: RiPlantLine
  },
  {
    name: "Disease",
    path: "/disease",
    Icon: FaDisease
  },
  {
    name: "Pest",
    path: "/pest",
    Icon: AiOutlineBug
  },
  {
    name: "Rack",
    path: "/rack",
    Icon: GiTable
  },
  {
    name: "Nutrient Terminology",
    path: "/nutrient-terminology",
    Icon: GiMedicines
  },
  {
    name: "Nutrient",
    path: "/nutrient",
    Icon: GiBlackBook
  }
];
