import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';

import { updateRack } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import {
  UpdateRackInput,
  UpdateRackMutation
} from "../../../types/api";

interface updateRackDetailProp {
  dispatch: AppDispatch;
  rackData: UpdateRackInput;
}
export const updateRackDetail:({
  dispatch,
  rackData
}: updateRackDetailProp) => Promise<void> = async ({
  dispatch,
  rackData
}: updateRackDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const updateResponse: GraphQLResult<UpdateRackMutation> =
      await API.graphql({
        query: updateRack,
        variables: { input: rackData }
      }) as GraphQLResult<UpdateRackMutation>;
    if (updateResponse && updateResponse.data?.updateRack) {
      toast.dark("Successfully updated rack");
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Update Rack.");
  } finally {
    dispatch(updateLoading(false));
  }
};
