/* eslint-disable security/detect-object-injection */
/* eslint-disable sonarjs/no-identical-functions */
import { AmplifyS3Image } from "@aws-amplify/ui-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from "react-redux"

import { updateLoading } from "../../redux/slice/appSlice";
import {
  ContactFormModel,
  DiseaseModel,
  image_url,
  NutrientModel,
  NutrientTerminologyModel,
  PestModel,
  PlantModel,
  RackModel} from "../../types/models";
import { BaseButton } from "../Buttons";

type TableComponentProps = {
  dataInput: Array<
    | PlantModel
    | DiseaseModel
    | NutrientModel
    | NutrientTerminologyModel
    | PestModel
    | RackModel
    | ContactFormModel
    | null
  >;
  colDisplay: string[];
  editFunc: (id: string | undefined) => void;
  deleteFunc: (id: string | undefined, index: number) => void;
  createFunc: () => void;
}
export const TableComponent: FunctionComponent<TableComponentProps> = ({
  dataInput,
  colDisplay,
  editFunc,
  deleteFunc,
  createFunc
}) => {
  const [data, updateData] = useState<Array<PlantModel | DiseaseModel | NutrientModel | NutrientTerminologyModel | PestModel | RackModel | ContactFormModel | null>>();
  const dispatch = useDispatch();
  
  useEffect(() => {
    updateData(dataInput);
  }, [dataInput]);
  
  return (
    <table className="w-full shadow-lg table-auto">
      <thead>
        <tr>
          <th colSpan={colDisplay.length + 1} className="px-2 py-4 text-right">
            <div className="float-right px-4 w-36">
              <BaseButton
                label="Create"
                onClick={() => {
                  dispatch(updateLoading(true))
                  createFunc();
                }}
              />
            </div>
          </th>
        </tr>
        <tr className="leading-normal text-gray-800 uppercase bg-gray-300">
          {colDisplay.map((value) => (
            <th key={value} className="px-6 py-3 text-left">
              {value}
            </th>
          ))}
          <th className="px-6 py-3 text-center">Actions</th>
        </tr>
      </thead>
      <tbody className="font-light text-gray-800">
        {data &&
          data.length > 0 &&
          data.map((value, index) => (
            <tr
              key={value?.id}
              className={`border-b border-gray-300 hover:bg-gray-300 ${
                index % 2 !== 0 ? " bg-gray-100" : ""
              }`}
            >
              {colDisplay.map((key: string) => {
                if (
                  key === "image_url" ||
                  key === "deficient_image" ||
                  key === "toxic_image"
                )
                  return (
                    <td key={key} className="px-6 py-3 text-left">
                      {value &&
                        key in value &&
                        value[key].map((image: image_url) => (
                          <AmplifyS3Image key={image.key} imgKey={image.key} />
                        ))}
                    </td>
                  );
                return (
                  <td key={key} className="px-6 py-3 text-left">
                    {value && key in value ? ReactHtmlParser(value[key]) : ""}
                  </td>
                );
              })}
              <td className="px-6 py-3 text-center whitespace-nowrap">
                <div className="flex justify-center item-center">
                  <div
                    className="w-5 mr-2 transform hover:text-purple-500 hover:scale-150"
                    onClick={() => {
                      dispatch(updateLoading(true))
                      editFunc(value?.id);
                    }}
                    onKeyDown={() => {
                      dispatch(updateLoading(true))
                      editFunc(value?.id);
                    }}
                    role="button"
                    tabIndex={-1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg>
                  </div>
                  <div
                    className="w-5 mr-2 transform hover:text-purple-500 hover:scale-150"
                    onClick={() => {
                      dispatch(updateLoading(true))
                      deleteFunc(value?.id, index);
                      dispatch(updateLoading(false))
                    }}
                    onKeyDown={() => {
                      dispatch(updateLoading(true))
                      deleteFunc(value?.id, index);
                      dispatch(updateLoading(false))
                    }}
                    role="button"
                    tabIndex={-1}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </div>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
