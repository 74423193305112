import React, { FunctionComponent } from "react";

interface TextButtonProp {
  label: string;
  onClick: () => void;
  isRight?: boolean;
}
export const TextButton: FunctionComponent<TextButtonProp> = ({
  label,
  onClick,
  isRight
}) => (
  <span
    role="button"
    onClick={onClick}
    className={`${
      isRight && "float-right"
    } text-green-600 hover:text-green-400 active:text-green-800`}
    tabIndex={-1}
    onKeyDown={onClick}
  >
    {label}
  </span>
);

TextButton.defaultProps = {
  isRight: false
};
