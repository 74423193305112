import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import {
  FieldArrayMethodProps,
  FieldValues,
  UseFormSetValue
} from "react-hook-form";
import { toast } from 'react-toastify';

import { getNutrient } from "../../../graphql/queries";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { GetNutrientQuery } from "../../../types/api";

interface getNutrientDetailProp {
  dispatch: AppDispatch;
  id: string | undefined;
  setValue: UseFormSetValue<FieldValues>;
  deficient_append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  toxic_append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
}
export const getNutrientDetail: ({
  dispatch,
  id,
  setValue,
  deficient_append,
  toxic_append
}: getNutrientDetailProp) => Promise<void> = async ({
  dispatch,
  id,
  setValue,
  deficient_append,
  toxic_append
}: getNutrientDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const data: GraphQLResult<GetNutrientQuery> = await API.graphql({
      query: getNutrient,
      variables: { id }
    }) as GraphQLResult<GetNutrientQuery>;
    if (data.data?.getNutrient) {
      const nutrientData = data.data?.getNutrient;
      setValue("id", nutrientData.id);
      setValue("name", nutrientData.name);
      setValue("short_name", nutrientData.short_name);
      setValue("type", nutrientData.type);
      setValue("importance", nutrientData.importance);
      setValue("deficient_symptom", nutrientData.deficient_symptom);
      setValue("leaf_position", nutrientData.leaf_position);
      nutrientData.deficient_image?.forEach((image_url) => {
        deficient_append({
          id: image_url?.key,
          bucket: image_url?.bucket,
          region: image_url?.region,
          key: image_url?.key
        });
      });
      setValue("worsen", nutrientData.worsen);
      setValue("solution", nutrientData.solution);
      setValue("toxic_symptom", nutrientData.toxic_symptom);
      nutrientData.toxic_image?.forEach((image_url) => {
        toxic_append({
          id: image_url?.key,
          bucket: image_url?.bucket,
          region: image_url?.region,
          key: image_url?.key
        });
      });
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Get Nutrient by ID.");
  } finally {
    dispatch(updateLoading(false));
  }
};
