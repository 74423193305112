import React, {
  FunctionComponent,
  useEffect,
  useReducer,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { TableComponent } from "../../components/Table";
import { deleteRackById, getRackDetailList } from "../../services";
import { Rack } from "../../types/api";

export const RackDashboard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [racks, updateRacks] = useState<Array<Rack|null>>([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    getRackDetailList({ updateRacks, dispatch });
  }, [dispatch]);

  const navigate = useNavigate();
  const colDisplay = ["name", "type", "image_url"];
  const editFunc = (id: string | undefined) => {
    navigate(`/rack/${id}`);
  };
  const deleteFunc = async (id: string | undefined, index: number) => {
    deleteRackById({ dispatch, forceUpdate, id, index, updateRacks });
  };
  const createFunc = () => {
    navigate("/rack/create");
  };

  return (
    <>
      <div>Racks page</div>
      <TableComponent
        dataInput={racks}
        colDisplay={colDisplay}
        createFunc={createFunc}
        editFunc={editFunc}
        deleteFunc={deleteFunc}
      />
    </>
  );
};
