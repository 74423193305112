import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { NavigateFunction } from "react-router-dom";
import { toast } from 'react-toastify';

import { createDisease } from "../../../graphql/mutations";
import { updateLoading } from "../../../redux/slice/appSlice";
import { AppDispatch } from "../../../redux/store";
import { CreateDiseaseInput, CreateDiseaseMutation } from "../../../types/api";

interface createDiseaseDetailProp {
  dispatch: AppDispatch;
  diseaseData: CreateDiseaseInput;
  navigate: NavigateFunction;
}

export const createDiseaseDetail: ({
  dispatch,
  diseaseData,
  navigate
}: createDiseaseDetailProp) => Promise<void> = async ({
  dispatch,
  diseaseData,
  navigate
}: createDiseaseDetailProp) => {
  try {
    dispatch(updateLoading(true));
    const createResult: GraphQLResult<CreateDiseaseMutation> =
      await API.graphql({
        query: createDisease,
        variables: { input: diseaseData }
      }) as  GraphQLResult<CreateDiseaseMutation>;
    if (createResult && createResult.data?.createDisease) {
      navigate(`/disease/${createResult.data.createDisease.id}`)
    }
  } catch (err) {
    // console.log(err)
    toast.dark("Error: Create Disease.");
  } finally {
    dispatch(updateLoading(true));
  }
};
