import React, {
  FunctionComponent,
  useEffect,
  useReducer,
  useState
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { TableComponent } from "../../components/Table";
import {
  deleteContactFormById,
  getContactFormList
} from "../../services";
import { ContactForm } from "../../types/api";

export const ContactFormDashboard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [contactForms, updateContactForms] = useState<Array<ContactForm|null>>([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  useEffect(() => {
    getContactFormList({ updateContactForms, dispatch });
  }, [dispatch]);

  const navigate = useNavigate();
  const colDisplay = ["name", "type", "image_url"];
  const editFunc = (id: string | undefined) => {
    navigate(`/contact-form/${id}`);
  };
  const deleteFunc = async (id: string | undefined, index: number) => {
    deleteContactFormById({
      dispatch,
      forceUpdate,
      id,
      index,
      updateContactForms
    });
  };
  const createFunc = () => {
    // navigate("/contactForm/create");
    toast("Error: User cannot create contact form.")
  };

  return (
    <>
      <div>ContactForms page</div>
      <TableComponent
        dataInput={contactForms}
        colDisplay={colDisplay}
        createFunc={createFunc}
        editFunc={editFunc}
        deleteFunc={deleteFunc}
      />
    </>
  );
};
